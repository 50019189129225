import {
  cn,
  Flex,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";
import { PlayerStatSelector } from "./PlayerStatSelector";
import { Stats } from "./PlayersOverview";
import { PlayerStats } from "@suns/api/generated-client/apollo";

interface PlayerStatsTableProps {
  title: string;
  stats: Stats[];
  loading: boolean;
  error?: boolean | null;
  setStatParams: (params: string) => void;
  statsMeasureType: string;
  setStatsMeasureType: (value: string) => void;
  statsPerMode: string;
  setStatsPerMode: (value: string) => void;
}

export function PlayerStatsTable({
  title,
  stats,
  loading,
  error,
  statsMeasureType,
  setStatsMeasureType,
  statsPerMode,
  setStatsPerMode,
}: PlayerStatsTableProps) {
  return (
    <>
      {stats?.length || error ? (
        <Flex direction="down" gap="sm">
          <Text heading variant="muted">
            {title}
          </Text>
          <PlayerStatSelector
            statsMeasureType={statsMeasureType}
            setStatsMeasureType={(value) => setStatsMeasureType(value)}
            statsPerMode={statsPerMode}
            setStatsPerMode={(value) => setStatsPerMode(value)}
          />
        </Flex>
      ) : null}
      {!loading ? (
        <>
          {error && (
            <Table>
              <TableHeader>
                <TableRow>
                  {stats &&
                    stats[0]?.statsHeaders?.map((heading, index) => (
                      <TableHead
                        className="whitespace-pre-wrap px-[0.85rem] text-[10px] font-bold "
                        key={heading + index}
                      >
                        {heading}
                      </TableHead>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="whitespace-normal first:[&>*]:text-center">
                  <TableCell
                    colSpan={stats && stats[0]?.statsHeaders.length + 1}
                    className="w-full bg-gray-100 text-center"
                  >
                    There was an issue retrieving stats
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {stats?.length ? (
            <Table
              className={cn("rounded border", [
                "max-md:[&_td]:p-1",
                "max-md:[&_td]:text-xs",
                "max-md:[&_th]:p-1",
                "max-md:[&_th]:text-xs",
              ])}
            >
              <TableHeader>
                <TableRow>
                  {stats &&
                    stats[0]?.statsHeaders?.map((heading, index) => (
                      <TableHead
                        className="whitespace-normal px-[0.85rem] text-[10px] font-bold "
                        key={heading + index}
                      >
                        {heading}
                      </TableHead>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {stats?.map((player, rowIndex) => (
                  <TableRow
                    key={`player-${rowIndex}`}
                    className="whitespace-nowrap first:[&>*]:text-center"
                  >
                    {player.playerStats?.map(
                      (stat: PlayerStats, cellIndex: number) => (
                        <TableCell key={`stat-${cellIndex}`}>
                          {String(stat)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : null}
        </>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}

export function SkeletonTable() {
  return (
    <Table
      className={cn("rounded border", [
        "max-md:[&_td]:p-1",
        "max-md:[&_td]:text-xs",
        "max-md:[&_th]:p-1",
        "max-md:[&_th]:text-xs",
      ])}
    >
      <TableHeader>
        <TableRow>
          {Array.from({ length: 18 }, (_, i) => i + 1).map((_, index) => (
            <TableHead className="px-[0.85rem]" key={index}>
              <Skeleton className="h-4 w-full" />
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          {Array.from({ length: 18 }, (_, i) => i + 1).map((_, index) => (
            <TableCell className={cn(index !== 0 && "border-l")} key={index}>
              <Skeleton className="h-4 w-10" />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}
