import { Page, PlayerHeadshot } from "@/components";
import {
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Skeleton,
  Tabs,
  TabsContent,
  Text,
} from "@suns/design-system";
import { MailIcon, PhoneIcon } from "@suns/design-system/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { agentByIdLoader } from "./loaders/agency-loader";
import { useAsync, useNavigateLogin } from "@/shared/hooks";
import { SunsApiError } from "@suns/api";
import AgentInfoForm from "./components/AgentInfoForm";
import { useState } from "react";
import { AgentResponse, PlayerRow } from "@suns/api/generated-client/apollo";
import { toast } from "@/shared/utils/toaster";
import { apolloApi } from "@/shared/api";
import { ToastType } from "@/shared/utils/toaster";

export interface AgentInfo {
  email: string | undefined;
  mobilePhone: string | undefined;
  workPhone: string | undefined;
}

export function AgentView() {
  const navigateLogin = useNavigateLogin();
  const { agentId } = useParams();
  const navigate = useNavigate();
  const {
    response: agent,
    loading: agentLoading,
    error: agentError,
  } = useAsync(agentByIdLoader, {
    id: Number(agentId),
  });

  const [agentResponse, setAgentResponse] = useState<AgentResponse | null>(
    null
  );
  const [saving, setSaving] = useState(false);

  if (agentError) {
    throw new SunsApiError("Error loading agent", {
      cause: agentError,
    });
  }

  const handleEmailClick = () => {
    window.open(`mailto:${agentResponse?.agent.email ?? agent?.agent.email}`);
  };

  const handlePhoneClick = (phone: string) => {
    window.open(`tel:${formatPhoneNumber(phone)}`);
  };

  const handleClickUpsertAgent = async (agentFormData: AgentInfo) => {
    try {
      setSaving(true);
      const updatedAgent = await apolloApi.upsertAgent(agentFormData);

      setAgentResponse(updatedAgent);

      toast(ToastType.SUCCESS, "Agent Updated");
    } catch (error) {
      if (error instanceof SunsApiError && error.authError) {
        toast(ToastType.ERROR, "Please log back in to update.");
        navigateLogin();
      }
      toast(ToastType.ERROR, "Unable to update agent. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <Page
      title={agent?.agent.name}
      loading={agentLoading}
      loadingState={<AgentViewLoading />}
      breadcrumbPaths={[
        { label: "Players", link: "/players" },
        { label: "Agents", link: "/players/agents" },
      ]}
      render={() => {
        return (
          <Container size="xl">
            <Flex direction="down" gap="md">
              <Card>
                <Flex direction="down" gap="md">
                  <Flex direction="down" gap="xs">
                    <Text heading size="2xl">
                      {agent?.agent.name}
                    </Text>
                    <Link
                      className="text-sm font-bold text-primary"
                      to={`/players/agents?agencyId=${agent?.agent.agency?.id}`}
                      state={{
                        agencyId: agent?.agent.agency?.id,
                      }}
                    >
                      {agent?.agent.agency?.name}
                    </Link>
                  </Flex>
                  <Flex gap="md">
                    <Button
                      className="max-sm:w-1/3"
                      onClick={() =>
                        handlePhoneClick(
                          agentResponse
                            ? agentResponse?.agent.mobilePhone
                            : agent?.agent.mobilePhone ?? ""
                        )
                      }
                    >
                      <PhoneIcon className="mr-2 size-4" />
                      <Text size="sm">Mobile</Text>
                    </Button>
                    <Button
                      variant="muted"
                      className="max-sm:w-1/3"
                      onClick={() =>
                        handlePhoneClick(
                          agentResponse
                            ? agentResponse?.agent.workPhone
                            : agent?.agent.workPhone ?? ""
                        )
                      }
                    >
                      <PhoneIcon className="mr-2 size-4" />
                      <Text size="sm">Work</Text>
                    </Button>
                    <Button
                      variant="muted"
                      className="max-sm:w-1/3"
                      onClick={handleEmailClick}
                    >
                      <MailIcon className="mr-2 size-4" />
                      <Text size="sm">Email</Text>
                    </Button>
                  </Flex>
                  {agent?.agent && (
                    <AgentInfoForm
                      agent={agentResponse?.agent ?? agent?.agent}
                      saving={saving}
                      onUpsert={handleClickUpsertAgent}
                    />
                  )}
                </Flex>
              </Card>
              <Card>
                <Tabs defaultValue="players">
                  <TabsContent value="players">
                    {agentLoading ? (
                      <PlayersSkeleton />
                    ) : (
                      <>
                        {agent?.agent.players && agent?.agent.players.length ? (
                          <Grid gap="md" columns={["md:2", "lg:3"]}>
                            {agent?.agent.players?.map((player: PlayerRow) => {
                              return (
                                <Button
                                  className="justify-start"
                                  variant="outline"
                                  size="xl"
                                  key={player.id}
                                  onClick={() => {
                                    navigate(`/players/${player.id}`);
                                  }}
                                >
                                  <Flex gap="sm" align="center">
                                    <PlayerHeadshot
                                      src={player.image}
                                      nbaPlayerId={player.nbaId}
                                      apolloLeagueId={
                                        player.currentTeams?.[0]
                                          ?.domesticLeagueId
                                      }
                                      size="xs"
                                    />
                                    {player.lastName}, {player.firstName}
                                  </Flex>
                                </Button>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Text size="sm" muted className="text-center">
                            There are no players assigned to this agent.
                          </Text>
                        )}
                      </>
                    )}
                  </TabsContent>
                </Tabs>
              </Card>
            </Flex>
          </Container>
        );
      }}
    />
  );
}

function AgentViewLoading() {
  return (
    <Container size="xl">
      <Flex direction="down" gap="md">
        <Card>
          <Flex direction="down" gap="md">
            <Flex direction="down" gap="sm">
              <Skeleton className="h-8 w-72" />
              <Skeleton className="h-4 w-48" />
            </Flex>
            <Flex gap="md">
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
            </Flex>
            <Grid columns="3" gap="sm">
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
            </Grid>
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
}

function PlayersSkeleton() {
  return (
    <Grid gap="md" columns={["md:2", "lg:3"]}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Skeleton key={index} className="h-10 w-full" />
      ))}
    </Grid>
  );
}

export function formatPhoneNumber(value: string | undefined): string {
  if (!value) return "";

  let digits = value.replace(/\D/g, "");

  if (!digits.startsWith("1") && digits.length > 0) {
    digits = "1" + digits;
  }

  if (digits.length <= 1) {
    return digits;
  }

  let formatted = "";

  formatted += digits[0];

  const rest = digits.slice(1);

  if (rest.length > 0) {
    formatted += "(";
  }

  if (rest.length <= 3) {
    formatted += rest;
    return formatted;
  }

  const area = rest.slice(0, 3);
  let next = rest.slice(3);

  formatted += area + ")";

  if (next.length > 0) {
    formatted += "";
  }

  if (next.length <= 3) {
    formatted += next;
    return formatted;
  }

  const mid = next.slice(0, 3);
  next = next.slice(3);

  formatted += mid;

  if (next.length > 0) {
    formatted += "-";
  }

  formatted += next;

  return formatted;
}
