import axios from "axios";
import { ApiResponse } from "./types/responses";
import {
  LeagueQueryParams,
  LeagueResponse,
  LeaguesResponse,
  PlayerQueryParams,
  PlayerResponse,
  PlayersResponse,
  ReportQueryParams,
  ReportResponse,
  ReportsResponse,
  ReportUpsertParams,
  TeamQueryParams,
  TeamResponse,
  TeamsResponse,
  IntelQueryParams,
  IntelCollectionResponse,
  GameQueryParams,
  GamesResponse,
  GameResponse,
  SecondSpectrumStatsResponse,
  PlayerCacheQueryParams,
  PlayerCacheResponse,
  IntelResponse,
  IntelUpsertParams,
  ReportAuthor,
  IntelAuthor,
  AssessmentQueryParams,
  AssessmentsResponse,
  AssessmentUpsertParams,
  AssessmentResponse,
  AgentQueryParams,
  AgentUpsertParams,
  AgentResponse,
  AgentsResponse,
  AgencyQueryParams,
  AgenciesResponse,
} from "../generated-client/apollo";

import dayjs from "dayjs";
import { SunsApiError } from "./error";

export class ApolloApi {
  url: string;
  getToken: () => Promise<string>;
  constructor({
    url,
    getToken,
  }: {
    url?: string;
    getToken: () => Promise<string>;
  }) {
    this.url = url || "https://dna.phxapollo.com/api/v1";
    this.getToken = getToken;

    this.getLeague = this.getLeague.bind(this);
    this.getLeagues = this.getLeagues.bind(this);
    this.getTeam = this.getTeam.bind(this);
    this.getTeams = this.getTeams.bind(this);
    this.getPlayer = this.getPlayer.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
    this.getReport = this.getReport.bind(this);
    this.getReports = this.getReports.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.getPlayerGames = this.getPlayerGames.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async get<Response = any, Params = any>({
    url,
    params,
  }: {
    url: string;
    params: Params;
  }): Promise<ApiResponse> {
    const token = await this.getToken();

    try {
      const response = await axios<Response>({
        method: "get",
        url: `${this.url}${url}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
        paramsSerializer: { indexes: null },
      });
      return {
        status: 200,
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status || 400,
          data: null,
          error,
        };
      } else {
        throw error;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async post<Response = any, Body = any>({
    url,
    body,
  }: {
    url: string;
    body: Body;
  }): Promise<ApiResponse> {
    const token = await this.getToken();

    try {
      const response = await axios<Response>({
        method: "post",
        url: `${this.url}${url}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return {
        status: 200,
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status || 400,
          data: null,
          error,
        };
      } else {
        throw error;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async delete<Response = any, Body = any>({
    url,
    body,
  }: {
    url: string;
    body: Body;
  }): Promise<ApiResponse> {
    const token = await this.getToken();

    try {
      const response = await axios<Response>({
        method: "delete",
        url: `${this.url}${url}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return {
        status: 200,
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status || 400,
          data: null,
          error,
        };
      } else {
        throw error;
      }
    }
  }

  /* LEAGUE ROUTES */

  async getLeague(leagueId: number): Promise<LeagueResponse> {
    const response = await this.get({
      url: `/leagues/${leagueId}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the league.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getLeagues(params: LeagueQueryParams): Promise<LeaguesResponse> {
    const response = await this.get({ url: `/leagues`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting leagues.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  /* TEAM ROUTES */

  async getTeam(teamId: number): Promise<TeamResponse> {
    const response = await this.get({
      url: `/teams/${teamId}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting teams.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getTeams(params: TeamQueryParams): Promise<TeamsResponse> {
    const response = await this.get({ url: `/teams`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting teams.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  /* PLAYER ROUTES */

  async getPlayer(playerId: number): Promise<PlayerResponse> {
    const response = await this.get({
      url: `/players/${playerId}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the player.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getPlayers(params: PlayerQueryParams): Promise<PlayersResponse> {
    const response = await this.get({ url: `/players`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting the players.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  /* REPORT ROUTES */

  async getReport(reportId: number): Promise<ReportResponse> {
    const response = await this.get({
      url: `/reports/${reportId}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the report.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getReports(params: ReportQueryParams): Promise<ReportsResponse> {
    const response = await this.get({ url: `/reports`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting reports.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getReportAuthors(): Promise<ReportAuthor[]> {
    const response = await this.get({ url: `/reports/authors`, params: {} });

    if (response.error) {
      throw new SunsApiError("There was an error getting report authors.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async saveReport(params: ReportUpsertParams): Promise<ReportResponse> {
    const response = await this.post({
      url: `/reports`,
      body: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error saving the report.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getGames(params: GameQueryParams): Promise<GamesResponse> {
    if (params.gameDate) {
      const date = new Date(params.gameDate);
      date.setHours(0, 0, 0, 0);
      params.gameDate = dayjs(date).utc().toString();
    }

    const response = await this.get({
      url: `/games`,
      params: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the games.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getGameById(gameId: number): Promise<GameResponse> {
    const response = await this.get({
      url: `/games/${gameId}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the game.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async deleteReport(reportId: number): Promise<void> {
    const response = await this.delete({
      url: `/reports/${reportId}`,
      body: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error deleting the report.", {
        cause: response.error,
      });
    }
  }

  async getIntelById(id: number): Promise<IntelResponse> {
    const response = await this.get({
      url: `/intel/${id}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the intel.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getIntel(params: IntelQueryParams): Promise<IntelCollectionResponse> {
    const response = await this.get({ url: `/intel`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting intel.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getIntelAuthors(): Promise<IntelAuthor[]> {
    const response = await this.get({ url: `/intel/authors`, params: {} });

    if (response.error) {
      throw new SunsApiError("There was an error getting report authors.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async saveIntel(params: IntelUpsertParams): Promise<IntelResponse> {
    const response = await this.post({
      url: `/intel`,
      body: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error saving intel.", {
        cause: response.error,
      });
    }

    return response.data as IntelResponse;
  }

  async deleteIntel(id: number): Promise<void> {
    const response = await this.delete({
      url: `/intel/${id}`,
      body: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error deleting the intel.", {
        cause: response.error,
      });
    }
  }

  async getAgencies(params: AgencyQueryParams): Promise<AgenciesResponse> {
    const response = await this.get({
      url: `/agencies`,
      params: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting agents.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getAgents(params: AgentQueryParams): Promise<AgentsResponse> {
    const response = await this.get({
      url: `/agents`,
      params: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting agents.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getAgentById(id: number): Promise<AgentResponse> {
    const response = await this.get({
      url: `/agents/${id}`,
      params: {},
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting agents.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async upsertAgent(params: AgentUpsertParams): Promise<AgentResponse> {
    const response = await this.post({
      url: `/agents`,
      body: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error updating the agent.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getSecondSpectrumStats(
    nbaId: string
  ): Promise<SecondSpectrumStatsResponse> {
    const response = await this.get({
      url: `/stats/secondspectrum`,
      params: { nbaId },
    });

    if (response.error) {
      throw new SunsApiError(
        "There was an error getting second spectrum stats.",
        {
          cause: response.error,
        }
      );
    }

    return response.data;
  }

  async getPlayerCache(
    params: PlayerCacheQueryParams
  ): Promise<PlayerCacheResponse> {
    const response = await this.get({
      url: `/players/cache`,
      params: params,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  }

  async getPlayerGames(
    playerId: number,
    params?: { offset?: number; limit?: number }
  ): Promise<GamesResponse> {
    const response = await this.get({
      url: `/players/${playerId}/games`,
      params,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  }

  async getAssessments(
    params: AssessmentQueryParams
  ): Promise<AssessmentsResponse> {
    const response = await this.get({ url: `/assessments`, params: params });

    if (response.error) {
      throw new SunsApiError("There was an error getting assessments.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async saveAssessment(
    params: AssessmentUpsertParams
  ): Promise<AssessmentResponse> {
    const response = await this.post({
      url: `/assessments`,
      body: params,
    });

    if (response.error) {
      throw new SunsApiError("There was an error saving assessments.", {
        cause: response.error,
      });
    }

    return response.data;
  }
}
