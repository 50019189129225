import { Button, Flex, SelectOption, Select } from "@suns/design-system";
import { FilterValues } from "../all-reports-listings";
import { X } from "lucide-react";
import { Filter } from "@suns/design-system/src/components/Filter/Filter";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { PlayerTeamSearch, TeamFilter } from "@/components";

interface ReportFiltersProps {
  type: "allReportsIntel" | "myReportsIntel";
  onChange: (filterValues: FilterValues) => void;
  onClear: () => void;
  filterValues: FilterValues;
  authors?: {
    key: string;
    name: string;
  }[];
  authorsLoading?: boolean;
}

export default function ReportFilters({
  type,
  onChange,
  onClear,
  filterValues,
  authors,
  authorsLoading,
}: ReportFiltersProps) {
  return (
    <Flex gap="sm" align="center" wrap={true} className="w-full">
      <PlayerTeamSearch
        playerOnly
        onChange={(id) => {
          onChange({ ...filterValues, playerId: id || undefined });
        }}
        defaultId={
          filterValues.playerId
            ? { id: filterValues.playerId, type: "player" }
            : undefined
        }
      />

      {type === "allReportsIntel" && (
        <Select
          className="w-36 bg-white"
          value={filterValues.level || PlayerLevel.PRO}
          onValueChange={(value) =>
            onChange({ ...filterValues, level: value, teamIds: [] })
          }
        >
          <SelectOption value={PlayerLevel.PRO} id={PlayerLevel.PRO}>
            {PlayerLevel.PRO}
          </SelectOption>
          <SelectOption value={PlayerLevel.AMATEUR} id={PlayerLevel.AMATEUR}>
            {PlayerLevel.AMATEUR}
          </SelectOption>
        </Select>
      )}

      <TeamFilter
        value={filterValues.teamIds?.map(String) || []}
        onChange={(selectedKeys: string[]) => {
          onChange({ ...filterValues, teamIds: selectedKeys.map(Number) });
        }}
      />

      {authors && (
        <Filter
          avoidCollisions={false}
          title={authorsLoading ? "Loading..." : "Author"}
          value={filterValues.authorUsername || []}
          onChange={(value) =>
            onChange({
              ...filterValues,
              authorUsername: value as string[],
            })
          }
          options={authors?.map((author) => ({
            key: author.key,
            name: author.name,
          }))}
        />
      )}
      <Button variant="ghost" onClick={onClear}>
        <Flex gap="xs" align="center" justify="center">
          Reset
          <X className="w-4 pt-0.5" />
        </Flex>
      </Button>
    </Flex>
  );
}
