import { useEffect, useState } from "react";
import { apolloApi } from "@/shared/api";
import { PlayerCacheResponse } from "@suns/api/generated-client/apollo";
import { notify } from "@/components/bugsnag";
import { ApolloLeagueId } from "@suns/api";

export type PlayerCacheItem = {
  type: "player";
  key: string;
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  display: string;
  teamCode: string;
  leagueId: ApolloLeagueId;
};

type PlayerMap = Map<string, PlayerCacheItem>;
let PLAYER_MAP: PlayerMap | undefined;
let PLAYER_CACHE_PROMISE: Promise<PlayerCacheResponse>;

// THIS IS A TEMPORARY FIX TO CLEAR THE PLAYER LIST CACHE
localStorage.removeItem("playerListCache");

interface PlayerMapResponse {
  playerMap?: PlayerMap;
  loading: boolean;
}

export function usePlayerMap(): PlayerMapResponse {
  const [loading, setLoading] = useState(false);
  const [playerMap, setPlayerMap] = useState<PlayerMap | undefined>(PLAYER_MAP);

  useEffect(() => {
    if (playerMap || loading) {
      return;
    }

    PLAYER_CACHE_PROMISE = PLAYER_CACHE_PROMISE || apolloApi.getPlayerCache({});

    setLoading(true);
    PLAYER_CACHE_PROMISE.then((response) => {
      const PLAYER_MAP = new Map<string, PlayerCacheItem>();
      response.cache.forEach((player) =>
        PLAYER_MAP.set(`player-${player.id}`, {
          type: "player" as const,
          key: `player-${player.id}`,
          id: player.id,
          firstName: player.firstName,
          lastName: player.lastName,
          fullName: `${player.firstName} ${player.lastName}`,
          display: `${player.lastName}, ${player.firstName}`,
          teamCode: player.teamCode,
          leagueId: player.leagueId,
        })
      );
      setPlayerMap(PLAYER_MAP);
    })
      .catch((error) => {
        notify(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, playerMap };
}
