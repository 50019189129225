import { PlayerMetadataRow } from "@suns/api/generated-client/apollo";
import { IntelTag } from "@suns/api/generated-client/apollo";

export const PlayerMetadataRoleLabels = {
  [PlayerMetadataRow.hybridRole.PLAYMAKER]: "Playmaker (PM)",
  [PlayerMetadataRow.hybridRole.SHOOTER]: "Shooter (SH)",
  [PlayerMetadataRow.hybridRole.PERIMETER_SCORER]: "Perimeter Scorer (PS)",
  [PlayerMetadataRow.hybridRole.SLASHER_DRIVER]: "Slasher/Driver (SD)",
  [PlayerMetadataRow.hybridRole.INTERIOR_FINISHER]: "Interior Finisher (IF)",
  [PlayerMetadataRow.hybridRole.PERIMETER_DEFENDER]: "Perimeter Defender (PD)",
  [PlayerMetadataRow.hybridRole.INTERNAL_DEFENDER]: "Interior Defender (ID)",
};

export const PlayerMetadataPositionLabels = {
  [PlayerMetadataRow.hybridPosition.POINT_GUARD]: "Point Guard (PG)",
  [PlayerMetadataRow.hybridPosition.GUARD]: "Guard (G)",
  [PlayerMetadataRow.hybridPosition.WING]: "Wing (W)",
  [PlayerMetadataRow.hybridPosition.FORWARD]: "Forward (F)",
  [PlayerMetadataRow.hybridPosition.BIG]: "Big (B)",
};

export const PlayersIntelTagsLabels = {
  [IntelTag.BASKETBALL]: "Basketball",
  [IntelTag.BACKGROUND]: "Background",
  [IntelTag.CHARACTER]: "Character",
  [IntelTag.MEDICAL]: "Medical",
  [IntelTag.BODY]: "Body",
  [IntelTag.PSYCH]: "Psych",
  [IntelTag.COACHING]: "Coaching",
  [IntelTag.DRAFT]: "Draft",
  [IntelTag.NIL]: "NIL",
  [IntelTag.TRADE]: "Trade",
  [IntelTag.FRONT_OFFICE]: "Front Office",
  [IntelTag.FREE_AGENCY]: "Free Agency",
  [IntelTag.MINI_CAMP]: "Mini Camp",
  [IntelTag.CONTRACT]: "Contract",
};
