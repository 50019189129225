import { useAsync } from "@/shared/hooks/useAsync";
import { playerStatsLoader } from "../loaders/players-loader";
import { ApolloLeagueId, LeagueId } from "@suns/api";
import { useSearchParams } from "react-router-dom";
import { PlayerStatsTable } from "./PlayerStatsTable";
import { Flex, Text } from "@suns/design-system";
import { GetPlayerStatsParams } from "@suns/api/src/suns-api";
import { PlayerRow, PlayerStats } from "@suns/api/generated-client/apollo";

export interface Stats {
  statsHeaders: string[];
  playerStats: PlayerStats[];
}

export function PlayersOverview({ player }: { player: PlayerRow }) {
  const createSeasons = (startYear: number, numSeasons: number) => {
    return Array.from({ length: numSeasons }, (_, i) => {
      const year = startYear - i;
      const shortNext = (year + 1).toString().slice(2);
      return `${year}-${shortNext}`;
    });
  };

  const seasons = createSeasons(2024, 22).join(",");

  const [searchParams, setSearchParams] = useSearchParams();

  const getStatType = (paramKey: string, defaultValue: string) =>
    searchParams.get(paramKey) ?? defaultValue;

  const statParams = {
    statsPerModeNBASeason: getStatType("PerModeNBASeason", "PerGame"),
    statsMeasureTypeNBASeason: getStatType("MeasureTypeNBASeason", "Base"),

    statsPerModeNBAPlayoffs: getStatType("PerModeNBAPlayoffs", "PerGame"),
    statsMeasureTypeNBAPlayoffs: getStatType("MeasureTypeNBAPlayoffs", "Base"),

    statsPerModeGLeagueRegularSeason: getStatType(
      "PerModeGLeagueRegularSeason",
      "PerGame"
    ),
    statsMeasureTypeGLeagueRegularSeason: getStatType(
      "MeasureTypeGLeagueRegularSeason",
      "Base"
    ),

    statsPerModeGLeaguePlayoffs: getStatType(
      "PerModeGLeaguePlayoffs",
      "PerGame"
    ),
    statsMeasureTypeGLeaguePlayoffs: getStatType(
      "MeasureTypeGLeaguePlayoffs",
      "Base"
    ),

    statsPerModeGLeagueShowcase: getStatType(
      "PerModeGLeagueShowcase",
      "PerGame"
    ),
    statsMeasureTypeGLeagueShowcase: getStatType(
      "MeasureTypeGLeagueShowcase",
      "Base"
    ),
  };

  const isNbaPlayer = player.currentTeams?.some(
    (team) => team.domesticLeagueId === ApolloLeagueId.NBA
  );

  const isGLeaguePlayer = player.currentTeams?.some(
    (team) => team.domesticLeagueId === ApolloLeagueId.GLEAGUE
  );

  const playerNbaId = player.nbaId?.toString() ?? "";
  const {
    response: nbaRegularSeasonStats,
    loading: nbaRegularSeasonStatsLoading,
    error: nbaRegularSeasonStatsError,
  } = useAsync(
    playerStatsLoader,
    {
      leagueId: LeagueId.NBA,
      perMode:
        statParams.statsPerModeNBASeason as GetPlayerStatsParams["perMode"],
      season: seasons,
      seasonType: "Regular Season",
      playerId: playerNbaId,
      measureType:
        statParams.statsMeasureTypeNBASeason as GetPlayerStatsParams["measureType"],
    },
    !isNbaPlayer
  );

  const {
    response: nbaPlayoffStats,
    loading: nbaPlayoffStatsLoading,
    error: nbaPlayoffStatsError,
  } = useAsync(
    playerStatsLoader,
    {
      leagueId: LeagueId.NBA,
      perMode:
        statParams.statsPerModeNBAPlayoffs as GetPlayerStatsParams["perMode"],
      season: seasons,
      seasonType: "Playoffs",
      playerId: playerNbaId,
      measureType:
        statParams.statsMeasureTypeNBAPlayoffs as GetPlayerStatsParams["measureType"],
    },
    !isNbaPlayer
  );

  const {
    response: gLeagueRegularSeasonStats,
    loading: gLeagueRegularSeasonStatsLoading,
    error: gLeagueRegularSeasonStatsError,
  } = useAsync(
    playerStatsLoader,
    {
      leagueId: LeagueId.GLeague,
      perMode:
        statParams.statsPerModeGLeagueRegularSeason as GetPlayerStatsParams["perMode"],
      season: seasons,
      seasonType: "Regular Season",
      playerId: playerNbaId,
      measureType:
        statParams.statsMeasureTypeGLeagueRegularSeason as GetPlayerStatsParams["measureType"],
    },
    !isGLeaguePlayer
  );

  const {
    response: gLeagueStatsShowcase,
    loading: gLeagueStatsShowcaseLoading,
    error: gLeagueStatsShowcaseError,
  } = useAsync(
    playerStatsLoader,
    {
      leagueId: LeagueId.GLeague,
      perMode:
        statParams.statsPerModeGLeagueShowcase as GetPlayerStatsParams["perMode"],
      season: seasons,
      seasonType: "Showcase",
      playerId: playerNbaId,
      measureType:
        statParams.statsMeasureTypeGLeagueShowcase as GetPlayerStatsParams["measureType"],
    },
    !isGLeaguePlayer
  );

  const {
    response: gLeagueStatsPlayoffs,
    loading: gLeagueStatsPlayoffsLoading,
    error: gLeagueStatsPlayoffsError,
  } = useAsync(
    playerStatsLoader,
    {
      leagueId: LeagueId.GLeague,
      perMode:
        statParams.statsPerModeGLeaguePlayoffs as GetPlayerStatsParams["perMode"],
      season: seasons,
      seasonType: "Playoffs",
      playerId: playerNbaId,
      measureType:
        statParams.statsMeasureTypeGLeaguePlayoffs as GetPlayerStatsParams["measureType"],
    },
    !isGLeaguePlayer
  );

  const handleStatsChange = (key: string, value: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      [key]: value,
    });
  };

  return (
    <Flex direction="down" gap="md" className="mt-4">
      {isNbaPlayer || isGLeaguePlayer ? (
        <>
          <PlayerStatsTable
            title="NBA Regular Season Stats"
            setStatParams={(value) =>
              handleStatsChange("MeasureTypeNBASeason", value)
            }
            stats={nbaRegularSeasonStats as Stats[]}
            loading={nbaRegularSeasonStatsLoading}
            error={nbaRegularSeasonStatsError ? true : null}
            statsMeasureType={statParams.statsMeasureTypeNBASeason}
            setStatsMeasureType={(value) =>
              handleStatsChange("MeasureTypeNBASeason", value)
            }
            statsPerMode={statParams.statsPerModeNBASeason}
            setStatsPerMode={(value) =>
              handleStatsChange("PerModeNBASeason", value)
            }
          />

          <PlayerStatsTable
            title="NBA Playoffs Stats"
            setStatParams={(value) =>
              handleStatsChange("MeasureTypeNBAPlayoffs", value)
            }
            stats={nbaPlayoffStats as Stats[]}
            loading={nbaPlayoffStatsLoading}
            error={nbaPlayoffStatsError ? true : null}
            statsMeasureType={statParams.statsMeasureTypeNBAPlayoffs}
            setStatsMeasureType={(value) =>
              handleStatsChange("MeasureTypeNBAPlayoffs", value)
            }
            statsPerMode={statParams.statsPerModeNBAPlayoffs}
            setStatsPerMode={(value) =>
              handleStatsChange("PerModeNBAPlayoffs", value)
            }
          />

          <PlayerStatsTable
            title="G League Regular Season Stats"
            setStatParams={(value) =>
              handleStatsChange("MeasureTypeGLeagueRegularSeason", value)
            }
            stats={gLeagueRegularSeasonStats as Stats[]}
            loading={gLeagueRegularSeasonStatsLoading}
            error={gLeagueRegularSeasonStatsError ? true : null}
            statsMeasureType={statParams.statsMeasureTypeGLeagueRegularSeason}
            setStatsMeasureType={(value) =>
              handleStatsChange("MeasureTypeGLeagueRegularSeason", value)
            }
            statsPerMode={statParams.statsPerModeGLeagueRegularSeason}
            setStatsPerMode={(value) =>
              handleStatsChange("PerModeGLeagueRegularSeason", value)
            }
          />

          <PlayerStatsTable
            title="G League Showcase Stats"
            setStatParams={(value) =>
              handleStatsChange("MeasureTypeGLeagueShowcase", value)
            }
            stats={gLeagueStatsShowcase as Stats[]}
            loading={gLeagueStatsShowcaseLoading}
            error={gLeagueStatsShowcaseError ? true : null}
            statsMeasureType={statParams.statsMeasureTypeGLeagueShowcase}
            setStatsMeasureType={(value) =>
              handleStatsChange("MeasureTypeGLeagueShowcase", value)
            }
            statsPerMode={statParams.statsPerModeGLeagueShowcase}
            setStatsPerMode={(value) =>
              handleStatsChange("PerModeGLeagueShowcase", value)
            }
          />

          <PlayerStatsTable
            title="G League Playoffs Stats"
            setStatParams={(value) =>
              handleStatsChange("MeasureTypeGLeaguePlayoffs", value)
            }
            stats={gLeagueStatsPlayoffs as Stats[]}
            loading={gLeagueStatsPlayoffsLoading}
            error={gLeagueStatsPlayoffsError ? true : null}
            statsMeasureType={statParams.statsMeasureTypeGLeaguePlayoffs}
            setStatsMeasureType={(value) =>
              handleStatsChange("MeasureTypeGLeaguePlayoffs", value)
            }
            statsPerMode={statParams.statsPerModeGLeaguePlayoffs}
            setStatsPerMode={(value) =>
              handleStatsChange("PerModeGLeaguePlayoffs", value)
            }
          />
        </>
      ) : (
        <>
          <Text size="lg" heading muted className="py-4 text-center">
            No stats available
          </Text>
        </>
      )}
    </Flex>
  );
}
