import { useState } from "react";
import { TeamCacheItem, usePlayerTeamSearch } from "@/shared/hooks";
import { Filter, FilterOption, Skeleton } from "@suns/design-system";
import React from "react";

type TeamFilterOption = TeamCacheItem & FilterOption;

interface TeamFilterProps {
  value: string[];
  onChange: (ids: string[], items: TeamFilterOption[]) => void;
  className?: string;
}

export const TeamFilter = React.forwardRef<HTMLButtonElement, TeamFilterProps>(
  ({ value, onChange, className }, ref) => {
    const [searchValue, setSearchValue] = useState("");
    const { results, lookupMap, loading } = usePlayerTeamSearch(searchValue, {
      teamOnly: true,
      limit: 30,
    });

    if (loading) {
      return <Skeleton className="h-10 w-40" />;
    }

    const options = [
      ...value.map((id) => {
        const item = lookupMap?.get(`team-${id}`);
        if (!item) {
          throw new Error(`Team not found: ${id}`);
        }
        return {
          ...item,
          key: id,
          name: item.display,
        };
      }),
      ...results.reduce<TeamFilterOption[]>((acc, result) => {
        if (value.includes(`${result.id}`)) {
          return acc;
        }
        acc.push({
          ...result,
          key: `${result.id}`,
          name: result.display,
        });
        return acc;
      }, []),
    ];

    return (
      <Filter
        innerRef={ref}
        className={className}
        shouldFilter={false}
        title="Add Team"
        value={value}
        onChange={onChange}
        options={options}
        onSearchChange={setSearchValue}
      />
    );
  }
);
