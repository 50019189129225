import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, cn, Flex, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import { playersLoader } from "./loaders/players-loader";
import {
  PlayerMetadataRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import dayjs from "dayjs";
import { Drawer, TeamLogo } from "@/components";
import { SlidersVerticalIcon } from "lucide-react";
import PlayerFilters from "./components/PlayerFilters";
import ColumnFilters from "./components/ColumnFilters";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "../reports/reports-const";
import { apolloGradeColor } from "@/shared/utils/colors";

export type PlayerColumn = ColumnDef<PlayerRow> & {
  accessorKey: string;
  header: string;
};

const columns: PlayerColumn[] = [
  {
    header: "Player",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.lastName + ", " + row.firstName,
    enableHiding: false,
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Team",
    accessorKey: "currentTeam",
    cell: (cell) => {
      const teams = cell.row.original.currentTeams;
      return (
        <Flex direction="right" align="center" gap="sm">
          {teams?.map((team, i) => (
            <Flex direction="right" align="center" gap="xs">
              <TeamLogo image={team.image} size="xxxs" />
              <Text size="sm" key={team.id} className="text-nowrap">
                {team.code}
                {i < teams.length - 1 ? "," : ""}
              </Text>
            </Flex>
          ))}
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Position",
    accessorKey: "position",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Scout Position",
    accessorKey: "scoutPosition",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutPosition
        ? ReportPositionLabels[row.metadata[0].scoutPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Grade",
    accessorKey: "grade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.hybridGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.value)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Scout Grade",
    accessorKey: "scoutGrade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.scoutGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.value)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Remaining Capacity",
    accessorKey: "remainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].hybridGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Scout Remaining Capacity",
    accessorKey: "scoutRemainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].scoutGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Role",
    accessorKey: "role",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Scout Role",
    accessorKey: "scoutRole",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutRole
        ? ReportRoleLabels[row.metadata[0].scoutRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (!row.height) return "--";
      const inches = Number(row.height);
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Weight",
    accessorKey: "weight",
    accessorFn: (row: PlayerRow) => (row.weight ? `${row.weight} lbs` : "--"),
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Wing",
    accessorKey: "wing",
    accessorFn: (row: PlayerRow) => {
      if (!row.wing) return "--";

      const feet = Math.floor(row.wing / 12);
      const remainingInches = row.wing % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Reach",
    accessorKey: "reach",
    accessorFn: (row: PlayerRow) => {
      if (!row.reach) return "--";

      const feet = Math.floor(row.reach / 12);
      const remainingInches = row.reach % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Home Town",
    accessorKey: "homeTown",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Home State",
    accessorKey: "homeState",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Home Country",
    accessorKey: "homeCountryCode",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Nationality",
    accessorKey: "nationalityCountryCode",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "High School",
    accessorKey: "highschool",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Years of Service",
    accessorKey: "yearsOfService",
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Target",
    accessorKey: "target",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
    sortingFn: "text",
  },
];

const defaultHiddenColumns = [
  "remainingCapacity",
  "scoutRemainingCapacity",
  "scoutPosition",
  "scoutRole",
  "scoutGrade",
  "wing",
  "reach",
  "homeTown",
  "homeState",
  "homeCountryCode",
  "nationalityCountryCode",
  "highschool",
  "yearsOfService",
  "target",
];

export function PlayersListings() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: "20",
    sort: "name",
    dir: "desc",
    hidden: defaultHiddenColumns.join(","),
  });
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const sorting = [
    {
      id: searchParams.get("sort") || "name",
      desc: searchParams.get("dir") !== "desc",
    },
  ];

  const pagination = {
    pageIndex: parseInt(searchParams.get("page") || "1") - 1,
    pageSize: parseInt(searchParams.get("pageSize") || "20"),
  };

  const columnVisibility = (searchParams.get("hidden") || "")
    .split(",")
    .reduce((acc, column) => {
      acc[column] = false;
      return acc;
    }, {} as VisibilityState);

  const { response, loading } = useAsync(playersLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0].id,
    sortDir: sorting[0].desc ? "DESC" : "ASC",
    ...Object.fromEntries(searchParams),
  });

  const handleSelectPlayer = (id: number | null) => {
    if (!id) return;
    navigate(`${id}`);
  };

  const updateSearchParams = (params = {}) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });
  };

  const numFilters = [...searchParams.keys()].filter(
    (key) => !["page", "pageSize", "sort", "dir", "hidden"].includes(key)
  ).length;

  return (
    <Flex direction="down" gap="md" className="mt-4 lg:mt-2">
      <Drawer open={filterDrawerOpen} onOpenChange={setFilterDrawerOpen}>
        <PlayerFilters
          columns={columns}
          columnVisibility={columnVisibility}
          onSubmit={() => {
            setFilterDrawerOpen(false);
          }}
        />
      </Drawer>
      <Flex direction="right" gap="md">
        <Button variant="outline" onClick={() => setFilterDrawerOpen(true)}>
          <SlidersVerticalIcon size={24} className="pr-2" /> Filters
          {numFilters > 0 && <Badge className="ml-2">{numFilters}</Badge>}
        </Button>
        <ColumnFilters
          columns={columns}
          columnVisibility={columnVisibility}
          onChange={(column, checked) => {
            const newColumnVisibility = {
              ...columnVisibility,
              [column]: !checked,
            };

            updateSearchParams({
              hidden: Object.keys(newColumnVisibility)
                .filter((key) => !newColumnVisibility[key])
                .join(","),
            });
          }}
        />
      </Flex>
      <DataTable
        loading={loading}
        columns={columns}
        data={response?.players || []}
        count={response?.count || 0}
        pagination={pagination}
        setPagination={(setPagination) => {
          const newPagination =
            typeof setPagination === "function"
              ? setPagination(pagination)
              : setPagination;

          updateSearchParams({
            page: `${newPagination.pageIndex + 1}`,
            pageSize: `${newPagination.pageSize}`,
          });
        }}
        sorting={sorting}
        setSorting={(setSorting) => {
          const newSort =
            typeof setSorting === "function" ? setSorting(sorting) : setSorting;

          const sort = newSort[0] ?? null;

          updateSearchParams({
            sort: sort.id,
            dir: sort.desc ? "asc" : "desc",
          });
        }}
        columnVisibility={columnVisibility}
        setColumnVisibility={(setColumnVisibility) => {
          const newColumnVisibility =
            typeof setColumnVisibility === "function"
              ? setColumnVisibility(columnVisibility)
              : setColumnVisibility;

          updateSearchParams({
            hidden: Object.keys(newColumnVisibility)
              .filter((key) => !newColumnVisibility[key])
              .join(","),
          });
        }}
        onRowClicked={(row) => handleSelectPlayer(row.original.id)}
      />
    </Flex>
  );
}
