import {
  Button,
  Grid,
  Select,
  SelectOption,
  Skeleton,
  Card,
  Flex,
  Text,
} from "@suns/design-system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApolloLeagueId, SunsApiError } from "@suns/api";
import { useLeagues } from "../hooks/useLeagues";
import { PlayerHeadshot } from "@/components/PlayerHeadshot/PlayerHeadshot";
import { PlayerTeamSearch } from "@/components/PlayerTeamSearch/PlayerTeamSearch";
import { AvailableLeagues } from "@/shared/const";
import { usePlayers, useTeams } from "@/shared/hooks";
import { Page } from "@/components";

function useParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const rawParams = {
    leagueId: searchParams.get("leagueId"),
    conference: searchParams.get("conference"),
    teamId: searchParams.get("teamId"),
  };

  const params = {
    leagueId: rawParams.leagueId ? Number(rawParams.leagueId) : undefined,
    conference: rawParams.conference || undefined,
    teamId: rawParams.teamId ? Number(rawParams.teamId) : undefined,
  };

  function setParams(newParams: Record<string, string | number | undefined>) {
    const formattedParams = Object.keys(newParams).reduce((acc, key) => {
      const value = newParams[key];
      if (value !== undefined) {
        acc.set(key, value.toString());
      }
      return acc;
    }, new URLSearchParams());
    setSearchParams(formattedParams);
  }

  return { params, setParams };
}

export function IntelCreate() {
  const navigate = useNavigate();
  const { params, setParams } = useParams();

  const {
    leagues,
    loading: leaguesLoading,
    error: leaguesError,
  } = useLeagues();
  const {
    response: teamsReponse,
    loading: teamsLoading,
    error: teamsError,
  } = useTeams(params.leagueId);
  const { teams, conferences } = teamsReponse || {};
  const {
    players,
    loading: playersLoading,
    error: playersError,
  } = usePlayers(params.teamId);

  const handleSelectPlayerOrTeam = (
    id: number | null,
    type: "team" | "player" | null
  ) => {
    if (!id) return;

    navigate(`/reports/intel/create/${type}/${id}`);
  };

  const handleSelectPlayer = (playerId: string | null) => {
    if (!playerId) return;
    navigate(`/reports/intel/create/player/${playerId}`);
  };

  if (leaguesError) {
    throw new SunsApiError("There was an error loading leagues.", {
      cause: leaguesError,
    });
  }

  if (teamsError) {
    throw new SunsApiError("There was an error loading teams.", {
      cause: teamsError,
    });
  }

  if (playersError) {
    throw new SunsApiError("There was an error loading the roster.", {
      cause: playersError,
    });
  }

  const filteredLeagues =
    leagues
      ?.filter((league) => AvailableLeagues.includes(league.id))
      .sort((league1, league2) => {
        const order = [
          ApolloLeagueId.NBA,
          ApolloLeagueId.NCAA,
          ApolloLeagueId.GLEAGUE,
        ];
        const league1Index = order.indexOf(league1.id);
        const league2Index = order.indexOf(league2.id);
        if (league1Index === -1 && league2Index === -1) return 0;
        if (league1Index === -1) return 1;
        if (league2Index === -1) return -1;
        return league1Index - league2Index;
      }) || [];

  return (
    <Page
      title="Create Intel"
      render={() => {
        return (
          <Card>
            <Flex direction="down" gap="lg">
              <Flex direction="down" gap="sm">
                <Text heading>Search for a player or team</Text>
                <div className="w-[400px] xs:max-w-full">
                  <PlayerTeamSearch onChange={handleSelectPlayerOrTeam} />
                </div>
              </Flex>

              <Flex direction="down" gap="sm">
                <Text heading>Select player from roster</Text>
                <Flex gap="sm" wrap>
                  {leaguesLoading ? (
                    <Select
                      className="w-full min-w-36 md:w-auto"
                      placeholder="Loading leagues"
                      disabled
                    />
                  ) : (
                    <Select
                      placeholder="Select league"
                      className="w-full min-w-36 md:w-auto"
                      value={params.leagueId?.toString()}
                      onValueChange={(value) => {
                        setParams({
                          leagueId: value,
                          conference: undefined,
                          teamId: undefined,
                        });
                      }}
                    >
                      {filteredLeagues.map((league) => (
                        <SelectOption
                          key={league.id}
                          value={league.id.toString()}
                        >
                          {league.name}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                  {!params.leagueId ? null : teamsLoading ? (
                    <Select
                      className="w-full min-w-36 md:w-auto"
                      placeholder="Loading conferences"
                      disabled
                    />
                  ) : (
                    <Select
                      key={`conference-${params.leagueId}`}
                      className="w-full min-w-36 md:w-auto"
                      placeholder="Select conference"
                      value={params.conference}
                      onValueChange={(conference) => {
                        setParams({
                          ...params,
                          conference,
                          teamId: undefined,
                        });
                      }}
                    >
                      {conferences?.map((conference) => (
                        <SelectOption
                          key={`conference-${params.leagueId}-${conference.replace(
                            /\s/g,
                            ""
                          )}`}
                          value={conference}
                        >
                          {conference}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                  {!params.conference || !teams ? null : (
                    <Select
                      key={`team-${params.conference}`}
                      className="w-full min-w-36 md:w-auto"
                      placeholder="Select Team"
                      value={params.teamId?.toString()}
                      onValueChange={(value) =>
                        setParams({
                          ...params,
                          teamId: value,
                        })
                      }
                    >
                      {teams[params.conference]?.map((team) => (
                        <SelectOption
                          key={`team-${params.conference}-${team.id}`}
                          value={team.id.toString()}
                        >
                          {team.fullName || team.name}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                </Flex>
                {!params.teamId ? (
                  <Text muted className="p-10 text-center">
                    Select a league, conference, and team
                  </Text>
                ) : playersLoading ? (
                  <PlayersSkeleton />
                ) : (
                  <Grid gap="md" columns={["md:2", "lg:3"]}>
                    {players?.map((player) => {
                      return (
                        <Button
                          className="justify-start"
                          variant="outline"
                          size="xl"
                          key={player.id}
                          onClick={() => handleSelectPlayer(String(player.id))}
                        >
                          <Flex gap="sm" align="center">
                            <PlayerHeadshot
                              size="xs"
                              src={player.image}
                              nbaPlayerId={Number(player.nbaId)}
                              apolloLeagueId={
                                player.currentTeams?.[0]?.domesticLeagueId
                              }
                            />
                            {player.lastName}, {player.firstName}
                          </Flex>
                        </Button>
                      );
                    })}
                  </Grid>
                )}
              </Flex>
            </Flex>
          </Card>
        );
      }}
    />
  );
}

function PlayersSkeleton() {
  return (
    <Grid gap="md" columns={["md:2", "lg:3"]}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Skeleton key={index} className="h-10 w-full" />
      ))}
    </Grid>
  );
}
