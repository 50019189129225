import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { ApolloLeagueId, ApolloPhase } from "@suns/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";

export { labsList } from "./labs";
export { glossaryList, StatName } from "./glossary";

export const Suns = {
  id: 1610612756,
  abbr: "PHX",
  fullName: "Phoenix Suns",
};

export const Mercury = {
  id: 1611661317,
  abbr: "PHO",
  fullName: "Phoenix Mercury",
};

export const defaultFilterValues = {
  level: PlayerLevel.PRO,
  teamIds: [],
  playerId: undefined,
  authorUsername: [],
  status: ReportQueryParams.status.PUBLISHED,
  page: 0,
};

export const AvailableLeagues = [
  ApolloLeagueId.NBA,
  ApolloLeagueId.NCAA,
  ApolloLeagueId.GLEAGUE,
  ApolloLeagueId.SPAIN,
  ApolloLeagueId.ITALY,
  ApolloLeagueId.AUSTRALIA,
  ApolloLeagueId.FRANCE_PRO_A,
  ApolloLeagueId.FRANCE_PRO_B,
  ApolloLeagueId.GREECE,
  ApolloLeagueId.TURKEY,
  ApolloLeagueId.RUSSIA,
  ApolloLeagueId.EURO_LEAGUE,
  ApolloLeagueId.EURO_CUP,
  ApolloLeagueId.CHAMPIONS_LEAGUE,
];

export const CURRENT_PHASE = ApolloPhase.PHASE_24_25_2;
