import { useMemo } from "react";
import { TeamCacheItem, useTeamMap } from "./useTeamMap";
import { PlayerCacheItem, usePlayerMap } from "./usePlayerMap";
import Fuse from "fuse.js";
import { ApolloLeagueId } from "@suns/api";

type UsePlayerTeamSearchOptions<
  T = {
    teamOnly?: boolean;
    playerOnly?: boolean;
  },
> = {
  limit?: number;
} & T;

interface UsePlayerTeamSearchResponse<T = TeamCacheItem | PlayerCacheItem> {
  results: T[];
  lookupMap?: Map<string, T>;
  loading: boolean;
}

function usePlayerTeamSearch(
  searchValue: string,
  options: UsePlayerTeamSearchOptions<{ teamOnly: true }>
): UsePlayerTeamSearchResponse<TeamCacheItem>;
function usePlayerTeamSearch(
  searchValue: string,
  options: UsePlayerTeamSearchOptions<{ playerOnly: true }>
): UsePlayerTeamSearchResponse<PlayerCacheItem>;
function usePlayerTeamSearch(
  searchValue: string,
  options?: UsePlayerTeamSearchOptions
): UsePlayerTeamSearchResponse;
function usePlayerTeamSearch(
  searchValue: string,
  options: UsePlayerTeamSearchOptions = {}
): UsePlayerTeamSearchResponse {
  const { teamOnly = false, playerOnly = false, limit = 10 } = options;
  const { teamMap, loading: teamMapLoading } = useTeamMap();
  const { playerMap, loading: playerMapLoading } = usePlayerMap();

  const lookupMap = useMemo(() => {
    if (!teamMap || !playerMap) {
      return;
    }

    if (teamOnly) {
      return teamMap;
    }
    if (playerOnly) {
      return playerMap;
    }
    return new Map<string, PlayerCacheItem | TeamCacheItem>([
      ...playerMap,
      ...teamMap,
    ]);
  }, [teamMap, playerMap, teamOnly, playerOnly]);

  const searchItems = useMemo(() => {
    if (!lookupMap) {
      return;
    }
    return Array.from(lookupMap.values());
  }, [lookupMap]);

  const fuse = useMemo(() => {
    if (!searchItems) {
      return;
    }

    return new Fuse(searchItems, {
      includeScore: true,
      ignoreFieldNorm: true,
      ignoreLocation: true,
      keys: ["fullName", { name: "teamName", weight: 2 }],
    });
  }, [searchItems]);

  const results = useMemo(() => {
    if (!fuse || !searchItems) {
      return [];
    }

    if (searchValue.length < 2) {
      return searchItems.slice(0, limit);
    }

    const leagueMultipliers: Partial<Record<ApolloLeagueId, number>> = {
      [ApolloLeagueId.NBA]: 0.05,
      [ApolloLeagueId.NCAA]: 0.1,
      [ApolloLeagueId.GLEAGUE]: 0.15,
    };

    const results = fuse
      .search(searchValue, { limit })
      .map((result) => {
        const leagueMultiplier = leagueMultipliers[result.item.leagueId];
        if (result.score && leagueMultiplier) {
          return {
            ...result,
            score: result.score * leagueMultiplier,
          };
        }
        return result;
      })
      .sort((a, b) => (a.score ?? 0) - (b.score ?? 0));

    return results.map((result) => result.item);
  }, [fuse, searchValue, limit, searchItems]);

  return {
    results,
    lookupMap,
    loading: teamMapLoading || playerMapLoading || !lookupMap,
  };
}

export { usePlayerTeamSearch };
