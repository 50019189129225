import { apolloApi } from "@/shared/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";

export async function viewPlayerLoader({ playerId }: { playerId: number }) {
  const { player } = await apolloApi.getPlayer(playerId);
  return { player };
}

export async function playersReportsLoader({
  status,
  offset = 0,
  limit = 20,
  playerId,
  type,
}: {
  status: ReportQueryParams.status.PUBLISHED;
  offset: number;
  limit: number;
  playerId?: number;
  type?: ReportQueryParams.type;
}) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    status,
    playerIds: playerId ? [playerId] : undefined,
    type,
  });

  return response;
}
