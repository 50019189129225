import { GameHero, TeamLogo } from "@/components";
import { StatName } from "@/shared/const";
import { useAsync, useInterval, useNavigateLogin } from "@/shared/hooks";
import { getApolloLeagueIdFromNbaGameId } from "@/shared/utils";
import {
  Alert,
  Button,
  Card,
  cn,
  Flex,
  Grid,
  Skeleton,
  SkeletonHeading,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { Info } from "@suns/design-system/icons";
import { useNavigate, useParams } from "react-router-dom";
import { boxScoreLoader } from "./loaders/boxscore-loader";
import { GameHeroSkeleton } from "@/components/GameHero/GameHero";
import { secondSpectrumLoader } from "./loaders/second-spectrum-loader";
import { useNotify } from "@/components/bugsnag";
import { PageError } from "@/components/PageError/PageError";
import {
  ShotsResponse,
  StartTypesResponse,
} from "@suns/api/generated-client/apollo";
import { SunsApiError } from "@suns/api";
import { toastError } from "@/shared/utils/toast-messages";
import { useState } from "react";
import { percent } from "@/shared/utils/helper-functions";

export function GameDnaLive() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const navigateLogin = useNavigateLogin();
  const notify = useNotify();

  const {
    loading: boxScoreLoading,
    response: boxScoreResponse,
    error: boxScoreError,
    refresh: boxScoreRefresh,
  } = useAsync(boxScoreLoader, {
    gameId,
  });
  useInterval(async () => {
    if (boxScoreLoading || boxScoreResponse?.game.gameStatus !== 2) {
      return;
    }
    boxScoreRefresh();
  }, 8000);

  const {
    loading: secondSpectrumLoading,
    response: secondSpectrumResponse,
    error: secondSpectrumError,
    refresh: secondSpectrumRefresh,
  } = useAsync(secondSpectrumLoader, {
    nbaId: gameId,
  });
  const secondSpectrumUnavailable =
    !secondSpectrumResponse && Boolean(secondSpectrumError);
  useInterval(async () => {
    if (
      secondSpectrumUnavailable ||
      secondSpectrumLoading ||
      boxScoreResponse?.game.gameStatus !== 2
    ) {
      return;
    }

    secondSpectrumRefresh();
  }, 15000);

  if (!gameId) {
    notify("A game ID is required to view this page.");
    navigate("/games");
    return null;
  }

  if (boxScoreError && !boxScoreResponse) {
    return (
      <PageError
        title="Error with NBA stream"
        message="There was an issue when calling the NBA for data. We've been alerted of the issue. In the meantime, you can try refreshing the page."
        error={boxScoreError}
        refresh
      />
    );
  }

  if (
    secondSpectrumError instanceof SunsApiError &&
    secondSpectrumError.authError
  ) {
    toastError("Please log back in to see game data.");
    navigateLogin();
    return null;
  } else if (secondSpectrumError) {
    notify(
      Error("There was an error retrieving the second spectrum stream.", {
        cause: secondSpectrumError,
      })
    );
  }

  if (!boxScoreResponse) {
    return <GameDnaLiveLoading />;
  }

  if (!gameId) {
    throw Error(
      "We couldn't detect a gameID when retrieving the box score stream."
    );
  }

  const { game, team, oppTeam, homeTeam, awayTeam } = boxScoreResponse;

  const [ssTeam, ssOppTeam] =
    secondSpectrumResponse?.teamA.teamId == team?.id
      ? [secondSpectrumResponse?.teamA, secondSpectrumResponse?.teamB]
      : [secondSpectrumResponse?.teamB, secondSpectrumResponse?.teamA];

  const fourFactorTable = [
    [
      padDecimals(awayTeam?.advancedBox.offRating) || "--",
      StatName.ORtg,
      padDecimals(homeTeam?.advancedBox.offRating) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.efgPct) || "--",
      StatName.EFGPct,
      percent(homeTeam?.advancedBox.efgPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.tovPct) || "--",
      StatName.TOVPct,
      percent(homeTeam?.advancedBox.tovPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.orbPct) || "--",
      StatName.ORBPct,
      percent(homeTeam?.advancedBox.orbPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.ftaRate) || "--",
      StatName.FTARate,
      percent(homeTeam?.advancedBox.ftaRate) || "--",
    ],
  ] satisfies [string | number, StatName, string | number][];

  const advancedBoxTable = [
    [
      StatName.NRtg,
      padDecimals(team?.advancedBox.netRating) || "--",
      padDecimals(team?.season?.netRating) || "--",
    ],
    [
      StatName.EFGPct,
      percent(team?.advancedBox.efgPct) || "--",
      percent(team?.season?.efgPct) || "--",
    ],
    [
      StatName.OppEfgPct,
      percent(oppTeam?.advancedBox.efgPct) || "--",
      percent(team?.season?.oppEfgPct) || "--",
    ],
    [
      StatName.TOVPct,
      percent(team?.advancedBox.tovPct) || "--",
      percent(team?.season?.tmTovPct) || "--",
    ],
    [
      StatName.OppTOVPct,
      percent(oppTeam?.advancedBox.tovPct) || "--",
      percent(team?.season?.oppTovPct) || "--",
    ],
    [
      StatName.ORBPct,
      percent(team?.advancedBox.orbPct) || "--",
      percent(team?.season?.orebPct) || "--",
    ],
    [
      StatName.DRBPct,
      percent(team?.advancedBox.drbPct) || "--",
      percent(team?.season?.drebPct) || "--",
    ],
    [
      StatName.PITP,
      team?.boxScore.pointsInThePaint || 0,
      team?.season?.ptsPaint || "--",
    ],
    [
      StatName.OppPITP,
      oppTeam?.boxScore.pointsInThePaint || 0,
      team?.season?.oppPtsPaint || "--",
    ],
  ] satisfies [StatName, number | string, number | string][];

  return (
    <>
      <Card className="mb-4 overflow-hidden">
        <div className="absolute -bottom-16 -right-16 hidden w-1/3 max-w-[400px] lg:block">
          <div className="absolute h-full w-full bg-gradient-to-r from-white to-white/75" />
          <TeamLogo
            nbaTeamId={game.homeTeam!.teamId!}
            className="w-full"
            apolloLeagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          />
        </div>
        <div className="absolute -bottom-16 -left-16 hidden w-1/3 max-w-[400px] lg:block">
          <div className="absolute h-full w-full bg-gradient-to-l from-white to-white/75" />
          <TeamLogo
            nbaTeamId={game.awayTeam!.teamId!}
            className="w-full"
            apolloLeagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          />
        </div>
        <GameHero
          apolloLeagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC!}
          gameClock={game.gameClock!}
          period={game.period!}
          homeTeam={{
            id: game.homeTeam!.teamId!,
            tricode: game.homeTeam!.teamTricode!,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam!.teamId!,
            tricode: game.awayTeam!.teamTricode!,
            score: game.awayTeam!.score!,
          }}
        />
        <Table className="mx-auto mt-3 max-w-[600px]">
          <TableCaption>
            This table displays the Four Factors, a proven identifier for
            winning games.
            <Button size="xs" variant="outline" className="ml-1" asChild>
              <a
                href="https://www.basketball-reference.com/about/factors.html"
                target="_blank"
              >
                Learn more.
              </a>
            </Button>
          </TableCaption>
          <TableBody>
            {fourFactorTable.map(([away, stat, home], index) => (
              <TableRow
                key={`four-factors-${index}`}
                className="text-md"
                justify="center"
              >
                <TableCell>{away}</TableCell>
                <TableCell className="w-24 font-bold">{stat}</TableCell>
                <TableCell>{home}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      {game.gameStatus !== 1 && (
        <Grid gap="md" columns="lg:2" align="start" className="max-lg:block">
          <Flex direction="down" gap="md" className="max-lg:mb-4">
            <Card className="max-lg:mb-4">
              <Text size="xl" heading className="mb-2">
                Advanced Box
              </Text>
              <Table>
                <TableHeader>
                  <TableRow>
                    {["Stat", "Value", "Team Avg."].map((heading, index) => (
                      <TableHead key={`advanced-box-heading-${index}`}>
                        {heading}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {advancedBoxTable.map(([stat, value, avg], index) => (
                    <TableRow key={`advanced-boxscore-${index}`}>
                      <TableCell className="font-bold">{stat}</TableCell>
                      <TableCell>{value}</TableCell>
                      <TableCell>{avg}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>

            <Card>
              <GameStatsTable
                heading="Start types"
                updatedAt={secondSpectrumResponse?.formattedClock}
                teamName={team?.name}
                oppTeamName={oppTeam?.name}
                teamStats={ssTeam?.startTypes}
                oppTeamStats={ssOppTeam?.startTypes}
                loading={secondSpectrumLoading && !secondSpectrumResponse}
                unavailable={secondSpectrumUnavailable}
                live={!secondSpectrumResponse?.gameOver}
                headings={[
                  "Start",
                  StatName.Share,
                  StatName.Poss,
                  StatName.EFGPct,
                  StatName.QSP,
                  StatName.ORtg,
                ]}
                renderStats={(startTypeRows) =>
                  startTypeRows?.map((startType, index) => (
                    <TableRow key={`startType-${index}`}>
                      <TableCell className="font-bold">
                        {startTypes[startType.startType]}
                      </TableCell>
                      <TableCell>{percent(startType.share, true)}</TableCell>
                      <TableCell>{startType.possessions}</TableCell>
                      <TableCell>{percent(startType.efgPct, true)}</TableCell>
                      <TableCell>{padDecimals(startType.qsp, 1)}</TableCell>
                      <TableCell>{padDecimals(startType.ortg, 1)}</TableCell>
                    </TableRow>
                  ))
                }
              />
            </Card>
          </Flex>
          <Flex direction="down" gap="md">
            <Card>
              <GameStatsTable
                heading="Shooting"
                updatedAt={secondSpectrumResponse?.formattedClock}
                teamName={team?.name}
                oppTeamName={oppTeam?.name}
                teamStats={ssTeam?.shooting}
                oppTeamStats={ssOppTeam?.shooting}
                loading={secondSpectrumLoading && !secondSpectrumResponse}
                unavailable={secondSpectrumUnavailable}
                live={!secondSpectrumResponse?.gameOver}
                headings={[
                  "Location",
                  StatName.FGM,
                  "Share",
                  StatName.EFGPct,
                  StatName.QSP,
                  StatName.QSM,
                  StatName.PPP,
                ]}
                renderStats={(shootingRows) =>
                  shootingRows?.map((shooting, index) => (
                    <TableRow
                      key={`shooting-${index}`}
                      className={cn(
                        [0].includes(index) && "bg-gray-100",
                        [0, 4, 6].includes(index) &&
                          "border-b-2 border-gray-300"
                      )}
                    >
                      <TableCell className="font-bold">
                        {shootingLocations[shooting.location]}
                      </TableCell>
                      <TableCell>
                        {shooting.fgm}/{shooting.fga}
                      </TableCell>
                      <TableCell>{percent(shooting.shotsPct)}</TableCell>
                      <TableCell>{percent(shooting.efgPct)}</TableCell>
                      <TableCell>{padDecimals(shooting.qsp, 1)}</TableCell>
                      <TableCell>{padDecimals(shooting.qsm, 1)}</TableCell>
                      <TableCell>{padDecimals(shooting.ppp, 2)}</TableCell>
                    </TableRow>
                  ))
                }
              />
            </Card>

            <Card>
              <GameStatsTable
                heading="Crashers"
                updatedAt={secondSpectrumResponse?.formattedClock}
                teamName={team?.name}
                oppTeamName={oppTeam?.name}
                teamStats={ssTeam?.crashers}
                oppTeamStats={ssOppTeam?.crashers}
                loading={secondSpectrumLoading && !secondSpectrumResponse}
                unavailable={secondSpectrumUnavailable}
                live={!secondSpectrumResponse?.gameOver}
                headings={[
                  "",
                  "Times",
                  StatName.Share,
                  "Misses",
                  StatName.ORB,
                  StatName.ORBPct,
                  StatName.PTS,
                ]}
                renderStats={(crasherRows) =>
                  crasherRows?.map((crashers, index) => (
                    <TableRow key={`crashers-${index}`}>
                      <TableCell className="font-bold">
                        {crashers.crashers}
                        &nbsp;
                        <span className="hidden md:inline">Crashed</span>
                      </TableCell>
                      <TableCell>{crashers.shots}</TableCell>
                      <TableCell>{percent(crashers.shotsPct)}</TableCell>
                      <TableCell>{crashers.orebOpportunities}</TableCell>
                      <TableCell>{crashers.oreb}</TableCell>
                      <TableCell>{percent(crashers.orebPct)}</TableCell>
                      <TableCell>{crashers.pts}</TableCell>
                    </TableRow>
                  ))
                }
              />
            </Card>
          </Flex>
        </Grid>
      )}
    </>
  );
}

function GameDnaLiveLoading() {
  return (
    <>
      <Card className="mb-4">
        <GameHeroSkeleton />
        <Table className="mx-auto mt-3 max-w-[600px]">
          <TableBody>
            {[1, 2, 3, 4].map((index) => (
              <TableRow
                key={`loading-${index}`}
                className="text-md"
                justify="center"
              >
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell className="w-24 font-bold">
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Grid gap="md" columns="2" align="start">
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
      </Grid>
    </>
  );
}

interface GameStatsTableProps<T> {
  heading: string;
  updatedAt?: string;
  teamName?: string;
  oppTeamName?: string;
  teamStats: T;
  oppTeamStats: T;
  loading: boolean;
  unavailable: boolean;
  live: boolean;
  headings: string[];
  renderStats: (stats: T) => React.ReactNode;
}

function GameStatsTable<T>({
  heading,
  updatedAt,
  teamName,
  oppTeamName,
  teamStats,
  oppTeamStats,
  loading,
  unavailable,
  live,
  headings,
  renderStats,
}: GameStatsTableProps<T>) {
  const [tab, setTab] = useState<string>("team");

  return (
    <Tabs value={tab} onValueChange={setTab}>
      <Flex align="center" justify="between" className="mb-2">
        <Text size="xl" heading>
          {heading}
        </Text>
        <TabsList>
          <TabsTrigger value="team">{teamName}</TabsTrigger>
          <TabsTrigger value="oppTeam">{oppTeamName}</TabsTrigger>
        </TabsList>
      </Flex>
      {unavailable ? (
        <NoTrackingAlert />
      ) : loading ? (
        <TableLoading />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              {headings.map((heading, index) => (
                <TableHead key={`crasher-heading-${index}`}>
                  {heading}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {renderStats(tab == "team" ? teamStats : oppTeamStats)}
          </TableBody>
          {live ? <TableCaption>Updated at {updatedAt}</TableCaption> : null}
        </Table>
      )}
    </Tabs>
  );
}

const TableLoading = () => (
  <Flex direction="down" gap="sm">
    <Skeleton className="h-10" />
    <Grid columns="3" gap="sm">
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
    </Grid>
    <Skeleton className="h-6" />
    <Grid columns="3" gap="sm">
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
    </Grid>
  </Flex>
);

const NoTrackingAlert = () => {
  return (
    <Alert
      title="No tracking data"
      description="Second Spectrum doesn't have tracking for this game at this time."
      variant="info"
      Icon={Info}
    />
  );
};

function padDecimals(input: number | null | undefined, decimals: number = 1) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }
  return input.toFixed(decimals);
}

const shootingLocations = {
  [ShotsResponse.location.ALL_SHOTS]: "All Shots",
  [ShotsResponse.location.RIM]: "Rim",
  [ShotsResponse.location.NON_RIM_PAINT]: "Non-Rim Paint",
  [ShotsResponse.location.MIDRANGE]: "Midrange",
  [ShotsResponse.location.ALL_3S]: "All 3s",
  [ShotsResponse.location.CORNER_3]: "Corner 3",
  [ShotsResponse.location.ABOVE_THE_BREAK_3]: "Above the Break 3",
  [ShotsResponse.location.C_AND_S_3]: "Catch and Shoot 3",
  [ShotsResponse.location.OFF_DRIBBLE_3]: "Off Dribble 3",
};

const startTypes = {
  [StartTypesResponse.startType.FGM_FTM]: `${StatName.FGM}/${StatName.FTM}`,
  [StartTypesResponse.startType.DREB]: StatName.DRB,
  [StartTypesResponse.startType.DEFOB]: "Def. OB",
  [StartTypesResponse.startType.SLOB]: StatName.SLOB,
  [StartTypesResponse.startType.TO]: `Liveball ${StatName.TOV}`,
};
