import { apolloApi } from "@/shared/api";
import { CURRENT_PHASE } from "@/shared/const";
import {
  ReportResponseItem,
  TeamRow,
  PlayerRow,
  ReportQueryParams,
} from "@suns/api/generated-client/apollo";

export async function phaseReportLoader({
  authorUsername,
}: {
  authorUsername: string;
}) {
  // get all published general reports
  const publishedGeneralReportsRequest = apolloApi.getReports({
    authorUsername: [authorUsername],
    status: ReportQueryParams.status.PUBLISHED,
    type: ReportResponseItem.type.GENERAL,
    phase: CURRENT_PHASE,
    limit: 1000,
  });

  //get existing phase reports for this user
  const phaseReportRequest = apolloApi.getReports({
    authorUsername: [authorUsername],
    type: ReportQueryParams.type.PHASE,
    phase: CURRENT_PHASE,
    limit: 1000,
  });

  const [publishedGeneralReportsResponse, phaseReportResponse] =
    await Promise.all([publishedGeneralReportsRequest, phaseReportRequest]);

  // group all reports by player id {playerId: [gameReport1, gameReport2, ...]}
  const playerIdReportsMap = publishedGeneralReportsResponse.reports.reduce<
    Record<string, ReportResponseItem[]>
  >((acc, report) => {
    if (!acc[report.playerId]) {
      acc[report.playerId] = [];
    }

    acc[report.playerId] = [...acc[report.playerId], report];
    return acc;
  }, {});

  // sort teamPlayersMap values
  const sortedPlayerIds = Object.keys(playerIdReportsMap).sort(
    (player1Id, player2Id) => {
      const player1 = playerIdReportsMap[player1Id][0].player;
      const player2 = playerIdReportsMap[player2Id][0].player;

      return comparePlayers(player1, player2);
    }
  );

  // Group phase reports by player ID
  const phaseReports = phaseReportResponse.reports.reduce<
    Record<string, ReportResponseItem>
  >((acc, report) => {
    acc[report.playerId] = report;
    return acc;
  }, {});

  const teamIdTeamMap = Object.values(playerIdReportsMap)
    .map(([report]) => report.player.currentTeams?.[0])
    .filter((team, index, self) => self.indexOf(team) === index)
    .reduce<Record<string, TeamRow>>((acc, team) => {
      if (!team) return acc;
      acc[team.id] = team;
      return acc;
    }, {});

  const teamPlayerIdsMap = Object.entries(playerIdReportsMap).reduce<
    Record<string, string[]>
  >((teams, [playerId, reports]) => {
    const teamId = reports[0].player.currentTeams?.[0]?.id;

    if (!teamId) return teams;

    if (!teams[teamId]) teams[teamId] = [];
    teams[teamId].push(playerId);
    return teams;
  }, {});

  // sort teamPlayersMap values
  Object.values(teamPlayerIdsMap).forEach((playerIds) => {
    playerIds.sort((player1Id, player2Id) => {
      const player1 = playerIdReportsMap[player1Id][0].player;
      const player2 = playerIdReportsMap[player2Id][0].player;
      return comparePlayers(player1, player2);
    });
  });

  const sortedTeamPlayersKeys = Object.keys(teamPlayerIdsMap).sort(
    (team1Id, team2Id) => {
      const teamReportCount1 = teamPlayerIdsMap[team1Id].reduce(
        (acc, playerId) => {
          return acc + playerIdReportsMap[playerId].length;
        },
        0
      );

      const teamReportCount2 = teamPlayerIdsMap[team2Id].reduce(
        (acc, playerId) => {
          return acc + playerIdReportsMap[playerId].length;
        },
        0
      );

      if (teamReportCount1 > teamReportCount2) return -1;
      if (teamReportCount1 < teamReportCount2) return 1;

      return (
        teamIdTeamMap[team1Id].fullName?.localeCompare(
          teamIdTeamMap[team2Id].fullName ?? ""
        ) || 0
      );
    }
  );

  return {
    hasReports:
      publishedGeneralReportsResponse.reports.length > 0 ||
      phaseReportResponse.reports.length > 0,
    playerIdReportsMap,
    sortedPlayerIds,
    sortedTeamPlayersKeys,
    teamIdTeamMap,
    teamPlayerIdsMap,
    phaseReports,
  };
}

function comparePlayers(player1: PlayerRow, player2: PlayerRow): number {
  if (player1.metadata?.[0]?.target && !player2.metadata?.[0]?.target)
    return -1;
  if (!player1.metadata?.[0]?.target && player2.metadata?.[0]?.target) return 1;

  const lastNameComparison = player1.lastName.localeCompare(player2.lastName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  return player1.firstName.localeCompare(player2.firstName);
}
