import { defer } from "react-router-dom";
import { FiltersService } from "../../swagger";
import { MultiValue } from "react-select";
import { MultiValuePlayersType } from "../../pages/box-scores/box-scores-types";
import dayjs from "dayjs";

type GenericMultiValueType<T> = MultiValue<T> | null;

export const getFilterData = async () => {
  try {
    const teamsList: {
      label: string;
      options: { label: string; value: string; id: number }[];
    }[] = [];
    const seasonsList: { label: string; value: string }[] = [];
    const teamsResponse = await FiltersService.getTeams();
    teamsResponse.data.forEach((group) => {
      const teams: { label: string; value: string; id: number }[] = [];
      group.options.forEach(
        (team: {
          TEAM_FULL_NAME: string;
          TEAM_ABBR: string;
          TEAM_ID: number;
        }) => {
          teams.push({
            value: team.TEAM_ABBR,
            label: team.TEAM_FULL_NAME,
            id: team.TEAM_ID,
          });
        }
      );
      teamsList.push({ label: group.label, options: teams });
    });

    const seasonsResponse = await FiltersService.getSeasons();
    seasonsResponse.data.forEach((season) => {
      seasonsList.push({ value: season.SEASON, label: season.SEASON });
    });

    return defer({ teamsList, seasonsList });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error:", error);
  }
};

export const shortenName = (fullName: string) => {
  const words = fullName.split(" ");
  if (words.length >= 2) {
    const firstName = words[0];
    const lastName = words[words.length - 1].includes("Jr.")
      ? words.splice(-2).join(" ")
      : words[words.length - 1];
    return `${firstName[0]}.${lastName}`;
  }
  return fullName;
};

export function dedupe<T>(a: T[]) {
  return [...new Set(a)];
}

export function dedupeBy<T>(arr: T[], fn: (item: T) => unknown) {
  return arr.filter((item, index, self) => {
    return index === self.findIndex((t) => fn(t) === fn(item));
  });
}

export function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export function extractValues<
  T extends { value: string | number; label: string; isDisabled?: boolean },
>(items: GenericMultiValueType<T>): string[] {
  if (!items) {
    return [];
  }
  return items.map((data) => data.value.toString());
}

export const convertPlayers = (ids: string[], arr: MultiValuePlayersType) => {
  const playerIds = new Set(ids?.map((id) => parseInt(id)));
  const filteredPlayers = arr?.filter((player) => playerIds.has(player.value));
  return (
    filteredPlayers?.map((player) => ({
      value: player.value,
      label: player.label,
      isDisabled: player.isDisabled,
    })) || []
  );
};

export const removeEmptyValues = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, v]) => v !== null && v !== "" && v !== undefined
    )
  );
};

export function formatWeight(weight: number): string {
  return `${weight} lbs`;
}

export function formatInchesToFeet(inches: number): string {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}`;
}

export function formatAge(birthDate: string): string {
  return dayjs().diff(dayjs(birthDate), "year", true).toFixed(1);
}

export function percent(
  input: number | null | undefined,
  skipRounding: boolean = false
) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }

  if (input > 1 || skipRounding) {
    return `${input.toFixed(1)}%`;
  }

  const percent = Math.round(input * 1000) / 10;
  return `${percent.toFixed(1)}%`;
}
