import { Page, PlayerHeadshot } from "@/components";
import {
  useAccount,
  useAsync,
  useNavigateLogin,
  useTeams,
} from "@/shared/hooks";
import { displayPhase, getLabel } from "@/shared/utils";
import {
  ApolloLeagueId,
  ApolloGrade,
  PlayerRole,
  PlayerPosition,
  SunsApiError,
} from "@suns/api";
import {
  Text,
  Flex,
  Select,
  SelectGroup,
  SelectOption,
  FormField,
  Card,
  Skeleton,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Grid,
} from "@suns/design-system";
import { CircleCheckBig, LoaderCircle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AssessmentFields,
  assessmentsLoader,
  saveAssessment,
} from "./assessments";
import { toast, ToastType } from "@/shared/utils/toaster";
import { notify } from "@/components/bugsnag";
import { CURRENT_PHASE } from "@/shared/const";

export function ReportsCreateAssessments() {
  const { response, loading, error } = useTeams(ApolloLeagueId.NBA);
  const [selectedTeamId, setSelectedTeamId] = useState<string>();

  if (error) {
    throw error;
  }

  return (
    <Page title="Player Assessments">
      <Card>
        <Flex direction="down" gap="md">
          <Flex direction="down">
            <Text muted heading>
              {displayPhase(CURRENT_PHASE, { includeYear: true })}
            </Text>
            <Text size="2xl" heading>
              Player Assessments
            </Text>
          </Flex>
          {loading ? (
            <Skeleton className="h-9 w-48" />
          ) : (
            <Select
              className="w-full md:w-48"
              value={selectedTeamId}
              onValueChange={setSelectedTeamId}
              placeholder="Select a team..."
            >
              {Object.entries(response.teams).map(([conference, teams]) => (
                <SelectGroup key={conference} label={conference}>
                  {teams.map((team) => (
                    <SelectOption key={`team-${team.id}`} value={`${team.id}`}>
                      {team.fullName}
                    </SelectOption>
                  ))}
                </SelectGroup>
              ))}
            </Select>
          )}

          {selectedTeamId && (
            <AssessmentsTable teamId={parseInt(selectedTeamId)} />
          )}
        </Flex>
      </Card>
    </Page>
  );
}

function AssessmentsTable({ teamId }: { teamId: number }) {
  const account = useAccount();
  const { response, loading, error } = useAsync(assessmentsLoader, {
    teamId,
    authorUsernames: [account.info!.username],
  });

  if (loading) {
    return (
      <Flex direction="down" gap="sm">
        <Skeleton className="h-10" />
        <Grid columns="3" gap="sm">
          <Skeleton className="h-6" />
          <Skeleton className="h-6" />
          <Skeleton className="h-6" />
        </Grid>
        <Skeleton className="h-6" />
        <Grid columns="3" gap="sm">
          <Skeleton className="h-6" />
          <Skeleton className="h-6" />
          <Skeleton className="h-6" />
        </Grid>
      </Flex>
    );
  }

  if (error) {
    throw error;
  }

  const { assessments, players } = response;

  return (
    <Table>
      <TableHeader>
        <TableRow justify="left">
          <TableHead>Player</TableHead>
          <TableHead className="w-1/6">Position</TableHead>
          <TableHead className="w-1/6">Apollo Grade</TableHead>
          <TableHead className="w-1/6">Remaining Capacity</TableHead>
          <TableHead className="w-1/6">Role</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {players?.map((player) => {
          if (player.metadata?.[0]?.target) return null;

          return (
            <AssessmentRow
              key={player.id}
              playerId={player.id}
              playerName={`${player.lastName}, ${player.firstName}`}
              nbaId={player.nbaId || undefined}
              defaultAssessment={assessments?.[player.id]}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

interface AssessmentRowProps {
  playerId: number;
  playerName: string;
  nbaId?: number;
  defaultAssessment?: AssessmentFields & { id: number };
}

function AssessmentRow({
  playerId,
  playerName,
  nbaId,
  defaultAssessment,
}: AssessmentRowProps) {
  const account = useAccount();
  const [saving, setSaving] = useState(false);
  const [complete, setComplete] = useState(isComplete(defaultAssessment));
  const id = useRef(defaultAssessment?.id);
  const [queuedSave, setQueuedSave] = useState<AssessmentFields>();
  const navigateLogin = useNavigateLogin();
  const { watch, control } = useForm<AssessmentFields>({
    defaultValues: defaultAssessment,
  });

  useEffect(() => {
    const { unsubscribe } = watch((assessment) => {
      if (saving) {
        setQueuedSave(assessment);
        return;
      }

      save(assessment);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, saving]);

  useEffect(() => {
    if (queuedSave && !saving) {
      save(queuedSave);
      setQueuedSave(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedSave, saving]);

  async function save(assessment: AssessmentFields) {
    const toSave = {
      ...assessment,
      id: id.current,
      playerId,
      authorUsername: account.info!.username,
      authorName: account.info!.name || "",
    };

    try {
      await saveAssessment(toSave, {
        beforeSave: () => {
          setSaving(true);
        },
        afterSave: (assessmentId) => {
          id.current = assessmentId;
          setSaving(false);
          setComplete(isComplete(assessment));
        },
      });
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        notify(new Error("Auth error trying to save report.", { cause: e }));
        navigateLogin();
      }
      notify(new Error("Error saving report.", { cause: e }));
      toast(ToastType.ERROR, `Unable to save report. Please try again.`);
    }
  }

  function isComplete(assessment?: AssessmentFields) {
    return (
      assessment?.role &&
      assessment?.position &&
      assessment?.grade &&
      assessment?.remainingCapacity
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Flex gap="sm" align="center">
          <PlayerHeadshot
            nbaPlayerId={nbaId || null}
            apolloLeagueId={nbaId ? ApolloLeagueId.NBA : undefined}
            src=""
            size="sm"
          />
          <Text heading>{playerName}</Text>
          {complete && <CircleCheckBig className="size-5 text-green" />}
          <div className="size-5">
            {saving && (
              <LoaderCircle className="h-full w-full animate-spin text-blue" />
            )}
          </div>
        </Flex>
      </TableCell>

      <TableCell>
        <FormField
          control={control}
          name="position"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Position"
              onValueChange={field.onChange}
              className="w-full"
            >
              {Object.values(PlayerPosition).map((position) => (
                <SelectOption key={`${playerId}-${position}`} value={position}>
                  {getLabel(position)}
                </SelectOption>
              ))}
            </Select>
          )}
        />
      </TableCell>

      <TableCell>
        <FormField
          control={control}
          name="grade"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Grade"
              onValueChange={field.onChange}
              className="w-full"
            >
              {Object.values(ApolloGrade).map((grade) => (
                <SelectOption key={`${playerId}-${grade}`} value={grade}>
                  {getLabel(grade)}
                </SelectOption>
              ))}
            </Select>
          )}
        />
      </TableCell>

      <TableCell>
        <FormField
          control={control}
          name="remainingCapacity"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Remaining Capacity"
              onValueChange={field.onChange}
              className="w-full"
            >
              {Object.values(ApolloGrade).map((grade) => (
                <SelectOption key={`${playerId}-${grade}`} value={grade}>
                  {getLabel(grade)}
                </SelectOption>
              ))}
            </Select>
          )}
        />
      </TableCell>

      <TableCell>
        <FormField
          control={control}
          name="role"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Role"
              onValueChange={field.onChange}
              className="w-full"
            >
              {Object.values(PlayerRole).map((role) => (
                <SelectOption key={`${playerId}-${role}`} value={role}>
                  {getLabel(role)}
                </SelectOption>
              ))}
            </Select>
          )}
        />
      </TableCell>
    </TableRow>
  );
}
