import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import {
  allAuthorUsernamesLoader,
  allReportsLoader,
} from "./loaders/all-reports-loader";
import { PaginationControl, ReportsList } from "@/components";
import { useEffect, useState } from "react";
import { IntelListItem } from "@/pages/intel/intel-listings/components/IntelListItem";
import { SunsApiError } from "@suns/api";
import { defaultFilterValues } from "@/shared/const";
import { useSearchParams } from "react-router-dom";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import {
  IntelQueryParams,
  PlayerCacheItem,
  ReportQueryParams,
} from "@suns/api/generated-client/apollo";
import ReportFilters from "./components/ReportFilters";
import {
  allIntelAuthorUsernamesLoader,
  intelLoader,
} from "@/pages/intel/loaders/intel-loaders";

export interface FilterValues {
  level?: string;
  teamIds?: number[];
  playerId?: number;
  authorUsername?: string[];
  defaultSelectedPlayer?: PlayerCacheItem | null;
  status?: ReportQueryParams.status;
  reportsPageNumber?: number;
  intelPageNumber?: number;
  reportType?: string[];
}

const PAGE_SIZE = 20;

export function AllReportsListings() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamIds: searchParams.getAll("teamIds")?.map(Number),
    playerId: Number(searchParams.get("playerId")) || undefined,
    authorUsername: searchParams.getAll("authorUsername") || undefined,
    reportsPageNumber:
      Number(searchParams.get("reportsPageNumber")) || undefined,
  });

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const [activeTab, setActiveTab] = useState("reports");
  const [reportsPage, setReportsPage] = useState(
    filterValues.reportsPageNumber || 0
  );
  const [intelPage, setIntelPage] = useState(0);

  const {
    response: reportsResponse,
    loading: reportsLoading,
    error: reportsError,
  } = useAsync(allReportsLoader, {
    offset: reportsPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    level: filterValues.level,
    teamIds: filterValues.teamIds,
    playerId: filterValues.playerId,
    authorUsername: filterValues.authorUsername,
  });

  const {
    response: intelResponse,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelLoader, {
    offset: intelPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    level: filterValues.level as IntelQueryParams.level,
    teamIds: filterValues.teamIds,
    playerIds: filterValues.playerId ? [filterValues.playerId] : undefined,
    authorUsername: filterValues.authorUsername,
    status: IntelQueryParams.status.PUBLISHED,
  });

  const {
    response: reportsAuthorsResponse,
    loading: reportsAuthorsLoading,
    error: reportsAuthorsError,
  } = useAsync(allAuthorUsernamesLoader);

  const {
    response: intelAuthorsResponse,
    loading: intelAuthorsLoading,
    error: intelAuthorsError,
  } = useAsync(allIntelAuthorUsernamesLoader);

  const reportsAuthors = reportsAuthorsResponse?.map((author) => ({
    key: author.authorUsername,
    name: author.authorName,
  }));

  const intelAuthors = intelAuthorsResponse?.map((author) => ({
    key: author.authorUsername,
    name: author.authorName,
  }));

  const handleChangeFilterValues = (value: FilterValues) => {
    setReportsPage(0);
    setIntelPage(0);
    const updatedFilters = { ...filterValues, ...value };
    setFilterValues(updatedFilters);

    const params = new URLSearchParams();

    Object.entries(updatedFilters).forEach(([key, values]) => {
      if (values == null) return;

      if (Array.isArray(values)) {
        values.forEach((val) => val && params.append(key, val.toString()));
      } else {
        params.append(key, values.toString());
      }
    });

    setSearchParams(params);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  const handleSetPage = (page: number) => {
    setReportsPage(page - 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: (page - 1).toString(),
    });
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, reportsPage, intelPage]);

  if (reportsError) {
    throw new SunsApiError("Unable to load reports.", { cause: reportsError });
  }

  if (intelError) {
    throw new SunsApiError("Unable to load intel.", { cause: intelError });
  }

  if (reportsAuthorsError) {
    throw new SunsApiError("Unable to load authors.", {
      cause: reportsAuthorsError,
    });
  }

  if (intelAuthorsError) {
    throw new SunsApiError("Unable to load authors.", {
      cause: intelAuthorsError,
    });
  }

  return (
    <Card
      header={
        <Text size="xl" heading>
          All reports
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="reports">
            <Text>Reports</Text>
          </TabsTrigger>
          <TabsTrigger value="intel">
            <Text>Intel</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="reports">
          <Flex direction="down" gap="md">
            <ReportFilters
              type="allReportsIntel"
              onChange={handleChangeFilterValues}
              onClear={handleClear}
              filterValues={filterValues}
              authors={reportsAuthors || []}
              authorsLoading={reportsAuthorsLoading}
            />
            <ReportsList
              loading={reportsLoading}
              response={
                reportsResponse || {
                  reports: [],
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                  sortColumn: "createdAt",
                  sortDir: "DESC",
                }
              }
              setPage={handleSetPage}
            />
          </Flex>
        </TabsContent>
        <TabsContent value="intel">
          <ReportFilters
            type="allReportsIntel"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            authors={intelAuthors || []}
            authorsLoading={intelAuthorsLoading}
          />
          {!intelResponse || intelLoading ? (
            <ReportsLoading />
          ) : (
            <>
              {intelResponse.intel.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {intelResponse.intel.map((intel) => (
                    <IntelListItem
                      key={`report-${intel.id}`}
                      className="py-4"
                      intel={intel}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No intel found.
                </Text>
              )}
              <PaginationControl
                {...intelResponse}
                onPageClicked={(page) => {
                  setIntelPage(page - 1);
                  window.scrollTo({ top: 0 });
                }}
              />
            </>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
