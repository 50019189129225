import {
  Filter,
  Flex,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { PlayersReportListings } from "./PlayersReportListings";
import {
  IntelTag,
  PlayerRow,
  ReportQueryParams,
} from "@suns/api/generated-client/apollo";
import { useSearchParams } from "react-router-dom";
import { PlayersIntelListings } from "./PlayersIntelListings";
import { PlayersOverview } from "./PlayersOverview";
import { PlayersIntelTagsLabels } from "../players-const";

export function PlayerInfoSection({ player }: { player: PlayerRow }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {
    reportType: searchParams.get("reportType") ?? ReportQueryParams.type.PHASE,
    tags: searchParams.getAll("tags") ?? [],
  };

  const tabOptions = ["Overview", "Reports", "Intel"];

  function handleTabChange(newTab: string) {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      tab: newTab,
    });
  }

  function handleReportsFilterCheckboxChange(checked: boolean) {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      reportType: checked ? ReportQueryParams.type.PHASE : "All",
      reportsPageNumber: "0",
    });
  }

  function handleReportsFilterChange(values: string[]) {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      tags: values,
    });
  }

  const intelTagOptions = Object.values(IntelTag).map((tag) => ({
    key: tag,
    name: PlayersIntelTagsLabels[tag],
    value: tag,
  }));

  return (
    <Tabs
      defaultValue={searchParams.get("tab") ?? "Overview"}
      onValueChange={handleTabChange}
    >
      <TabsList>
        {tabOptions.map((tab) => (
          <TabsTrigger key={tab} value={tab}>
            {tab}
          </TabsTrigger>
        ))}
      </TabsList>
      <TabsContent value="Overview" className="w-full">
        <PlayersOverview player={player} />
      </TabsContent>
      <TabsContent value="Reports">
        <Flex direction="right" align="center" gap="sm" className="py-4">
          <Switch
            id="phase-mode"
            checked={params.reportType === ReportQueryParams.type.PHASE}
            onCheckedChange={handleReportsFilterCheckboxChange}
          />
          <Text size="xs">Only Phase Reports</Text>
        </Flex>
        <PlayersReportListings reportType={params.reportType} />
      </TabsContent>
      <TabsContent value="Intel">
        <Filter
          className="my-3"
          value={params.tags}
          title="Tags"
          onChange={(values: string[]) => {
            handleReportsFilterChange(values);
          }}
          options={intelTagOptions}
          noClear={true}
        />
        <PlayersIntelListings tags={params.tags} />
      </TabsContent>
    </Tabs>
  );
}
