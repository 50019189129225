import { PaginationControl } from "@/components";
import { IntelListItem } from "@/pages/intel/intel-listings/components/IntelListItem";
import { intelLoader } from "@/pages/intel/loaders/intel-loaders";
import { useAsync } from "@/shared/hooks";

import { SkeletonText } from "@suns/design-system";
import { SunsApiError } from "@suns/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";
import { Flex, Text } from "@suns/design-system";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export function PlayersIntelListings({ tags }: { tags: string[] }) {
  const PAGE_SIZE = 10;
  const { playerId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("intelPageNumber") ?? "0";
  const [publishedPage, setPublishedPage] = useState(Number(pageParam) || 0);

  const {
    loading: publishedLoading,
    response: publishedResponse,
    error: publishedError,
    refresh: refreshPublished,
  } = useAsync(intelLoader, {
    status: IntelQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    playerIds: playerId ? [Number(playerId)] : undefined,
    tags: tags,
  });

  const handleSetPage = (page: number) => {
    setPublishedPage(page - 1);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      intelPageNumber: (page - 1).toString(),
    });
  };

  const currentPublishedPage = Math.floor(
    (publishedResponse?.offset || 0) / (publishedResponse?.limit || 1)
  );

  useEffect(() => {
    if (publishedPage !== currentPublishedPage) {
      window.scrollTo({ top: 0 });
      refreshPublished();
    }
  }, [publishedPage, currentPublishedPage, refreshPublished]);

  if (publishedError) {
    throw new SunsApiError("Error loading the published intel.", {
      cause: publishedError,
    });
  }

  const handleTagClick = (tag: string) => {
    const paramTags = searchParams.getAll("tags");
    if (paramTags.includes(tag)) {
      const tagsArray = paramTags.filter((t) => t !== tag);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        tags: tagsArray,
      });
    } else {
      const tagsArray = [...paramTags, tag];
      setSearchParams({
        ...Object.fromEntries(searchParams),
        tags: tagsArray,
      });
    }
  };

  return (
    <>
      {publishedLoading ? (
        <IntelListingsLoading />
      ) : (
        <>
          {publishedResponse && publishedResponse?.intel?.length > 0 ? (
            <Flex direction="down" className="divide-y-2">
              {publishedResponse?.intel.map((intel) => (
                <IntelListItem
                  hideButtons
                  key={intel.id}
                  className="py-4"
                  intel={intel}
                  onTagClick={handleTagClick}
                />
              ))}
            </Flex>
          ) : (
            <Text className="py-10 text-center" size="lg" muted>
              No intel found.
            </Text>
          )}
          <PaginationControl
            {...publishedResponse}
            onPageClicked={handleSetPage}
          />
        </>
      )}
    </>
  );
}

function IntelListingsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
