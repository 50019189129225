/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerMetadataApolloGradeRow } from './PlayerMetadataApolloGradeRow';
import type { PlayerMetadataScoreRow } from './PlayerMetadataScoreRow';
export type PlayerMetadataRow = {
    target: PlayerMetadataRow.target;
    hybridPosition: PlayerMetadataRow.hybridPosition;
    scoutPosition: PlayerMetadataRow.scoutPosition;
    hybridRole: PlayerMetadataRow.hybridRole;
    scoutRole: PlayerMetadataRow.scoutRole;
    hybridGradeCurrent: PlayerMetadataApolloGradeRow;
    scoutGradeCurrent: PlayerMetadataApolloGradeRow;
    hybridGradeRemainingCapacity: PlayerMetadataApolloGradeRow;
    scoutGradeRemainingCapacity: PlayerMetadataApolloGradeRow;
    scoutScores: Array<PlayerMetadataScoreRow>;
    hybridScores: Array<PlayerMetadataScoreRow>;
};
export namespace PlayerMetadataRow {
    export enum target {
        TRADE = 'TRADE',
        FREE_AGENT = 'FREE_AGENT',
    }
    export enum hybridPosition {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum scoutPosition {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum hybridRole {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERNAL_DEFENDER = 'INTERNAL_DEFENDER',
    }
    export enum scoutRole {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERNAL_DEFENDER = 'INTERNAL_DEFENDER',
    }
}

