import { apolloApi } from "@/shared/api";
import { AgentQueryParams } from "@suns/api/generated-client/apollo/models/AgentQueryParams";

export async function agenciesLoader() {
  const response = await apolloApi.getAgencies({});
  return response;
}

export async function agentsLoader({
  offset,
  limit,
  sortColumn,
  sortDir,
  agencyId,
}: AgentQueryParams) {
  const response = await apolloApi.getAgents({
    offset,
    limit,
    sortColumn,
    sortDir,
    agencyId,
  });
  return response;
}

export async function agentByIdLoader({ id }: { id: number }) {
  const response = await apolloApi.getAgentById(id);

  if (response.agent.players && response.agent.players.length) {
    response.agent.players.sort((a, b) => {
      const nameA = `${a.lastName}, ${a.firstName}`.toLowerCase();
      const nameB = `${b.lastName}, ${b.firstName}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }
  return response;
}
