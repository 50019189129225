import { apolloApi, getPlayerStats } from "@/shared/api";
import { StatName } from "@/shared/const/glossary";
import { percent } from "@/shared/utils/helper-functions";
import { GetPlayerStatsParams } from "@suns/api/src/suns-api";
import { PlayerQueryParams } from "@suns/api/generated-client/apollo";

export async function playersLoader(params: PlayerQueryParams) {
  const response = await apolloApi.getPlayers(params);

  return response;
}

export async function playerStatsLoader({
  leagueId,
  perMode,
  season,
  seasonType,
  playerId,
  measureType,
}: GetPlayerStatsParams) {
  const response = await getPlayerStats({
    leagueId,
    perMode,
    season,
    seasonType,
    playerId,
    measureType,
  });

  const stats = response?.players?.map((player) => player.stats);
  if (stats) {
    stats.sort((a, b) => {
      const seasonA = a?.season ?? "";
      const seasonB = b?.season ?? "";
      return seasonB.localeCompare(seasonA);
    });
  }
  if (!stats) return { playerStats: [], statsHeaders: [] };

  const numericStats = stats.map((stat) =>
    Object.fromEntries(
      Object.entries(stat ?? {}).filter(
        ([, value]) => typeof value === "number" || typeof value === "string"
      )
    )
  );

  const mappedStats = await Promise.all(
    numericStats.map((stat) => parsePlayerStats(stat, measureType))
  );

  return mappedStats;
}

export async function parsePlayerStats(
  stats: Record<string, number | string | null>,
  measureType: string
) {
  interface StatMapping {
    key: keyof typeof stats;
    format: (value: number | string | null | undefined) => string;
  }

  const measureTypeConfigs = {
    Base: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PTS,
        StatName.FGM,
        StatName.FGA,
        StatName.FGPct,
        StatName.ThreePM,
        StatName.ThreePA,
        StatName.ThreePPct,
        StatName.FTM,
        StatName.FTA,
        StatName.FTPct,
        StatName.ORB,
        StatName.DRB,
        StatName.REB,
        StatName.AST,
        StatName.TOV,
        StatName.STL,
        StatName.BLK,
        StatName.PF,
        StatName.PlusMinus,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "pts", format: formatFixed },
        { key: "fgm", format: formatFixed },
        { key: "fga", format: formatFixed },
        { key: "fgPct", format: percent },
        { key: "fg3m", format: formatFixed },
        { key: "fg3a", format: formatFixed },
        { key: "fg3Pct", format: percent },
        { key: "ftm", format: formatFixed },
        { key: "fta", format: formatFixed },
        { key: "ftPct", format: percent },
        { key: "oreb", format: formatFixed },
        { key: "dreb", format: formatFixed },
        { key: "reb", format: formatFixed },
        { key: "ast", format: formatFixed },
        { key: "tov", format: formatFixed },
        { key: "stl", format: formatFixed },
        { key: "blk", format: formatFixed },
        { key: "pf", format: formatFixed },
        { key: "plusMinus", format: formatPlusMinus },
      ] as StatMapping[],
    },
    Advanced: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.ORtg,
        StatName.DRtg,
        StatName.NRtg,
        StatName.ASTPct,
        StatName.AstTo,
        StatName.AstRatio,
        StatName.ORBPct,
        StatName.DRBPct,
        StatName.RebPct,
        StatName.TORatio,
        StatName.EFGPct,
        StatName.TsPct,
        StatName.UsgPct,
        StatName.Pace,
        StatName.Pie,
        StatName.Poss,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "offRating", format: formatFixed },
        { key: "defRating", format: formatFixed },
        { key: "netRating", format: formatFixed },
        { key: "astPct", format: percent },
        { key: "astTo", format: formatFixed },
        { key: "astRatio", format: formatFixed },
        { key: "orebPct", format: percent },
        { key: "drebPct", format: percent },
        { key: "rebPct", format: percent },
        { key: "tmTovPct", format: formatFixed },
        { key: "efgPct", format: percent },
        { key: "tsPct", format: percent },
        { key: "usgPct", format: percent },
        { key: "pace", format: formatFixed },
        {
          key: "pie",
          format: formatTimes100,
        },
        { key: "poss", format: formatValue },
      ] as StatMapping[],
    },
    Misc: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PtsOffTov,
        StatName.Pts2ndChance,
        StatName.PtsFb,
        StatName.PITP,
        StatName.OppPtsOffTov,
        StatName.OppPts2ndChance,
        StatName.OppPtsFb,
        StatName.OppPITP,
        StatName.BLK,
        StatName.BLKA,
        StatName.PF,
        StatName.PFD,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "ptsOffTov", format: formatFixed },
        { key: "pts2ndChance", format: formatFixed },
        { key: "ptsFb", format: formatFixed },
        { key: "ptsPaint", format: formatFixed },
        { key: "oppPtsOffTov", format: formatFixed },
        { key: "oppPts2ndChance", format: formatFixed },
        { key: "oppPtsFb", format: formatFixed },
        { key: "oppPtsPaint", format: formatFixed },
        { key: "blk", format: formatFixed },
        { key: "blka", format: formatFixed },
        { key: "pf", format: formatFixed },
        { key: "pfd", format: formatFixed },
      ] as StatMapping[],
    },
    "Four Factors": {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.ORBPct,
        StatName.TOVPct,
        StatName.EFGPct,
        StatName.FTARate,
        StatName.OppEfgPct,
        StatName.OppFTARate,
        StatName.OppTOVPct,
        StatName.OppOrbPct,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "orebPct", format: percent },
        { key: "tmTovPct", format: formatFixed },
        { key: "efgPct", format: percent },
        { key: "ftaRate", format: percent },
        { key: "oppEfgPct", format: percent },
        { key: "oppFtaRate", format: percent },
        { key: "oppTovPct", format: percent },
        { key: "oppOrebPct", format: percent },
      ] as StatMapping[],
    },
    Scoring: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.PctFga2pt,
        StatName.PctFga3pt,
        StatName.PctPts2pt,
        StatName.PctPts2ptMr,
        StatName.PctPts3pt,
        StatName.PctPtsFb,
        StatName.PctPtsFt,
        StatName.PctPtsOffTov,
        StatName.PctPtsPaint,
        StatName.PctAst2pm,
        StatName.PctUast2pm,
        StatName.PctAst3pm,
        StatName.PctUast3pm,
        StatName.PctAstFgm,
        StatName.PctUastFgm,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "pctFga2pt", format: percent },
        { key: "pctFga3pt", format: percent },
        { key: "pctPts2pt", format: percent },
        { key: "pctPts2ptMr", format: percent },
        { key: "pctPts3pt", format: percent },
        { key: "pctPtsFb", format: percent },
        { key: "pctPtsFt", format: percent },
        { key: "pctPtsOffTov", format: percent },
        { key: "pctPtsPaint", format: percent },
        { key: "pctAst2pm", format: percent },
        { key: "pctUast2pm", format: percent },
        { key: "pctAst3pm", format: percent },
        { key: "pctUast3pm", format: percent },
        { key: "pctAstFgm", format: percent },
        { key: "pctUastFgm", format: percent },
      ] as StatMapping[],
    },
    Opponent: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.OppFGM,
        StatName.OppFGA,
        StatName.OppFGPct,
        StatName.Opp3PM,
        StatName.Opp3PA,
        StatName.Opp3PPct,
        StatName.OppFTM,
        StatName.OppFTA,
        StatName.OppFTPct,
        StatName.OppOREB,
        StatName.OppDREB,
        StatName.OppREB,
        StatName.OppAST,
        StatName.OppTOV,
        StatName.OppSTL,
        StatName.OppBLK,
        StatName.OppBLKA,
        StatName.OppPF,
        StatName.OppPfd,
        StatName.OppPts,
        StatName.OppEfgPct,
        StatName.OppPlusMinus,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "oppFgm", format: formatFixed },
        { key: "oppFga", format: formatFixed },
        { key: "oppFgPct", format: percent },
        { key: "oppFg3m", format: formatFixed },
        { key: "oppFg3a", format: formatFixed },
        { key: "oppFg3Pct", format: percent },
        { key: "oppFtm", format: formatFixed },
        { key: "oppFta", format: formatFixed },
        { key: "oppFtPct", format: percent },
        { key: "oppOreb", format: formatFixed },
        { key: "oppDreb", format: formatFixed },
        { key: "oppReb", format: formatFixed },
        { key: "oppAst", format: formatFixed },
        { key: "oppTov", format: formatFixed },
        { key: "oppStl", format: formatFixed },
        { key: "oppBlk", format: formatFixed },
        { key: "oppBlka", format: formatFixed },
        { key: "oppPf", format: formatFixed },
        { key: "oppPfd", format: formatFixed },
        { key: "oppPts", format: formatFixed },
        { key: "oppEfgPct", format: percent },
        { key: "oppPlusMinus", format: formatPlusMinus },
      ] as StatMapping[],
    },
    Usage: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.UsgPct,
        StatName.PctFgm,
        StatName.PctFga,
        StatName.PctFg3m,
        StatName.PctFg3a,
        StatName.PctFtm,
        StatName.PctFta,
        StatName.PctOreb,
        StatName.PctDreb,
        StatName.PctReb,
        StatName.PctAst,
        StatName.PctTov,
        StatName.PctStl,
        StatName.PctBlk,
        StatName.PctBlka,
        StatName.PctPf,
        StatName.PctPfd,
        StatName.PctPts,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "usgPct", format: percent },
        { key: "pctFgm", format: percent },
        { key: "pctFga", format: percent },
        { key: "pctFg3m", format: percent },
        { key: "pctFg3a", format: percent },
        { key: "pctFtm", format: percent },
        { key: "pctFta", format: percent },
        { key: "pctOreb", format: percent },
        { key: "pctDreb", format: percent },
        { key: "pctReb", format: percent },
        { key: "pctAst", format: percent },
        { key: "pctTov", format: percent },
        { key: "pctStl", format: percent },
        { key: "pctBlk", format: percent },
        { key: "pctBlka", format: percent },
        { key: "pctPf", format: percent },
        { key: "pctPfd", format: percent },
        { key: "pctPts", format: percent },
      ] as StatMapping[],
    },
    Defense: {
      headers: [
        "",
        StatName.TEAM,
        StatName.GP,
        StatName.Min,
        StatName.DRB,
        StatName.DRBPct,
        StatName.STL,
        StatName.PctStl,
        StatName.BLK,
        StatName.PctBlk,
        StatName.OppPtsOffTov,
        StatName.OppPts2ndChance,
        StatName.OppPtsFb,
        StatName.OppPITP,
        StatName.DefRimFgm,
        StatName.DefRimFga,
        StatName.DefRimFgPct,
      ],
      statsMapping: [
        { key: "season", format: formatValue },
        { key: "teamAbbreviation", format: formatFixed },
        { key: "gp", format: formatValue },
        { key: "min", format: formatFixed },
        { key: "dreb", format: formatFixed },
        { key: "drebPct", format: percent },
        { key: "stl", format: formatFixed },
        { key: "pctStl", format: percent },
        { key: "blk", format: formatFixed },
        { key: "pctBlk", format: percent },
        { key: "oppPtsOffTov", format: formatFixed },
        { key: "oppPts2ndChance", format: formatFixed },
        { key: "oppPtsFb", format: formatFixed },
        { key: "oppPtsPaint", format: formatFixed },
        { key: "defRimFgm", format: formatFixed },
        { key: "defRimFga", format: formatFixed },
        { key: "defRimFgPct", format: percent },
      ] as StatMapping[],
    },
  };

  const config =
    measureTypeConfigs[measureType as keyof typeof measureTypeConfigs];
  if (!config || !stats)
    return {
      playerStats: [],
      statsHeaders: [],
      error: "Error loading the player stats.",
    };

  const statsHeaders = config.headers.map((header) => header.toUpperCase());
  const playerStats = config.statsMapping.map(({ key, format }) => {
    const value = stats[key as keyof typeof stats] as
      | number
      | string
      | null
      | undefined;

    if (typeof value === "number") {
      return format(value);
    } else if (typeof value === "string") {
      return value;
    } else {
      return format(null);
    }
  });

  return { playerStats, statsHeaders };
}

function formatValue(value: string | null | undefined, fallback = "--") {
  if (!value) {
    return fallback;
  }

  return value;
}

function formatFixed(
  value: number | null | undefined,
  decimals = 1,
  fallback = "--"
) {
  if (value === null || value === undefined) return fallback;
  return value.toFixed(decimals);
}

function formatPlusMinus(value: number | null | undefined) {
  if (value === null || value === undefined) return "--";
  return value > 0 ? `+${value}` : value.toString();
}

function formatTimes100(value: number | null | undefined) {
  if (value === null || value === undefined) return "--";
  return formatFixed(value * 100);
}
