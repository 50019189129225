import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "@/pages/reports/reports-const";
import {
  PlayerMetadataRow,
  ReportApolloGrade,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import {
  Button,
  Command,
  CommandGroup,
  Filter,
  Flex,
  Input,
  Popover,
  Select,
  SelectOption,
  Text,
} from "@suns/design-system";
import { CircleXIcon, LoaderCircleIcon, PlusIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlayerColumn } from "../players-listings";
import { useLeagues } from "@/pages/intel/hooks/useLeagues";
import { VisibilityState } from "@tanstack/react-table";
import { PopoverTrigger } from "@suns/design-system/src/components/Popover/Popover";
import { PopoverContent } from "@radix-ui/react-popover";
import {
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@suns/design-system/src/components/Command/Command";
import ColumnFilters from "./ColumnFilters";
import { TeamFilter } from "@/components";

// const moneyFormatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   maximumFractionDigits: 0,
// });

export enum OperatorType {
  INCLUDES = "in",
  EXCLUDES = "ex",
  GREATER_THAN = "gt",
  LESS_THAN = "lt",
}

function labelForOperatorType(type: OperatorType) {
  switch (type) {
    case OperatorType.INCLUDES:
      return "Includes";
    case OperatorType.EXCLUDES:
      return "Excludes";
    case OperatorType.GREATER_THAN:
      return "Greater than or equal to";
    case OperatorType.LESS_THAN:
      return "Less than or equal to";
  }
}

interface PlayerFilter {
  label: string;
  key: string;
  availableOperators: OperatorType[];
  selectedOperator?: OperatorType;
  selectedValue?: string;
  renderInputField: (
    value?: string,
    onChange?: (value: string) => void
  ) => React.ReactNode;
}

const generalFilters: PlayerFilter[] = [
  {
    label: "Team",
    key: "team",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <TeamFilter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(",").trim());
        }}
      />
    ),
  },

  {
    label: "League",
    key: "league",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <LeagueSelectField value={value} onChange={onChange} />
    ),
  },
  {
    label: "Target",
    key: "target",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(PlayerMetadataRow.target).map((option) => ({
          key: option,
          searchKey: option,
          name:
            option === PlayerMetadataRow.target.FREE_AGENT
              ? "Free Agent Target"
              : "Trade Target",
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
];

const scoutingFilters: PlayerFilter[] = [
  {
    label: "Grade",
    key: "grade",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => {
      const grades = Object.keys(ReportApolloGrade.value);
      return (
        <Select value={value} onValueChange={onChange}>
          {grades.map((value, i) => (
            <SelectOption key={value} value={`${grades.length - i}`}>
              {
                ApolloGradeValueLabels[
                  value as keyof typeof ApolloGradeValueLabels
                ]
              }
            </SelectOption>
          ))}
        </Select>
      );
    },
  },
  {
    label: "Remaining Capacity",
    key: "remainingCapacity",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => {
      const grades = Object.keys(ReportApolloGrade.value);
      return (
        <Select value={value} onValueChange={onChange}>
          {grades.map((value, i) => (
            <SelectOption key={value} value={`${grades.length - i}`}>
              {
                ApolloGradeValueLabels[
                  value as keyof typeof ApolloGradeValueLabels
                ]
              }
            </SelectOption>
          ))}
        </Select>
      );
    },
  },
  {
    label: "Position",
    key: "position",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.position).map((option) => ({
          key: option,
          name: ReportPositionLabels[
            option as keyof typeof ReportPositionLabels
          ],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Role",
    key: "role",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.role).map((option) => ({
          key: option,
          searchKey: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
          name: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Scout Grade",
    key: "scoutGrade",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => {
      const grades = Object.keys(ReportApolloGrade.value);
      return (
        <Select value={value} onValueChange={onChange}>
          {grades.map((value, i) => (
            <SelectOption key={value} value={`${grades.length - i}`}>
              {
                ApolloGradeValueLabels[
                  value as keyof typeof ApolloGradeValueLabels
                ]
              }
            </SelectOption>
          ))}
        </Select>
      );
    },
  },
  {
    label: "Scout Remaining Capacity",
    key: "scoutRemainingCapacity",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => {
      const grades = Object.keys(ReportApolloGrade.value);
      return (
        <Select value={value} onValueChange={onChange}>
          {grades.map((value, i) => (
            <SelectOption key={value} value={`${grades.length - i}`}>
              {
                ApolloGradeValueLabels[
                  value as keyof typeof ApolloGradeValueLabels
                ]
              }
            </SelectOption>
          ))}
        </Select>
      );
    },
  },
  {
    label: "Scout Position",
    key: "scoutPosition",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.position).map((option) => ({
          key: option,
          searchKey:
            ReportPositionLabels[option as keyof typeof ReportPositionLabels],
          name: ReportPositionLabels[
            option as keyof typeof ReportPositionLabels
          ],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
  {
    label: "Scout Role",
    key: "scoutRole",
    availableOperators: [OperatorType.INCLUDES, OperatorType.EXCLUDES],
    renderInputField: (value, onChange) => (
      <Filter
        className="bg-white"
        value={
          value?.split(",").filter((listItem) => listItem.length > 0) ?? []
        }
        options={Object.keys(ReportResponseItem.role).map((option) => ({
          key: option,
          name: ReportRoleLabels[option as keyof typeof ReportRoleLabels],
        }))}
        onChange={(selectedKeys: string[]) => {
          onChange?.(selectedKeys.join(","));
        }}
      />
    ),
  },
];

const playerInfoFilters: PlayerFilter[] = [
  {
    label: "Age",
    key: "age",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Height",
    key: "height",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        value={value}
        onChange={(event) => {
          const digits = event.target.value.replace(/\D/g, "");
          if (digits && digits.length > 1) {
            const feet = digits.charAt(0);
            if (digits.length === 1) {
              onChange?.(`${feet}`);
            } else {
              const inches = Math.min(11, parseInt(digits.slice(1), 10));
              onChange?.(`${feet}'${inches}`);
            }
          } else {
            onChange?.(`${digits}`);
          }
        }}
      />
    ),
  },
  {
    label: "Weight",
    key: "weight",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Wingspan",
    key: "wing",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Reach",
    key: "reach",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
  {
    label: "Years of Service",
    key: "yearsOfService",
    availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
    renderInputField: (value, onChange) => (
      <Input
        type="number"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
      />
    ),
  },
];

// const contractFilters: PlayerFilter[] = [
//   {
//     label: "Salary",
//     key: "salary",
//     availableOperators: [OperatorType.GREATER_THAN, OperatorType.LESS_THAN],
//     renderInputField: (value, onChange) => (
//       <Input
//         onChange={(event) => {
//           const digits = parseInt(event.target.value.replace(/\D/g, ""), 10);
//           if (digits) {
//             onChange?.(moneyFormatter.format(digits));
//           } else {
//             onChange?.("");
//           }
//         }}
//         value={value}
//       />
//     ),
//   },
// ];

const availableFilters = [
  { groupLabel: "General", filters: generalFilters },
  { groupLabel: "Evaluation", filters: scoutingFilters },
  { groupLabel: "Player Info", filters: playerInfoFilters },
  // { groupLabel: "Contract", filters: contractFilters },
];

function filtersToSearchParams(filters: PlayerFilter[]) {
  return filters.reduce<Record<string, string>>((acc, filter) => {
    if (filter.selectedOperator && filter.selectedValue) {
      acc[`${filter.key}.${filter.selectedOperator}`] = filter.selectedValue;
    }

    return acc;
  }, {});
}

function searchParamsToFilters(searchParams: URLSearchParams): PlayerFilter[] {
  const filters: PlayerFilter[] = [];

  searchParams.forEach((value, key) => {
    const [filterKey, operator] = key.split(".");
    const filter = availableFilters
      .map((filterGroup) => filterGroup.filters)
      .flat()
      .find((filter) => filter.key === filterKey);

    if (filter && operator) {
      filters.push({
        ...filter,
        selectedOperator: operator as OperatorType,
        selectedValue: value,
      });
    }
  });

  return filters;
}

export default function PlayerFilters({
  columns,
  columnVisibility,
  onSubmit,
}: {
  columns: PlayerColumn[];
  columnVisibility: VisibilityState;
  onSubmit: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<PlayerFilter[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);

  useEffect(() => {
    const newFilters = searchParamsToFilters(searchParams);
    setFilters(newFilters);

    const hiddenColumns = searchParams.get("hidden")?.split(",") || [];
    setHiddenColumns(hiddenColumns);
  }, [searchParams]);

  useEffect(() => {
    const hidden = Object.keys(columnVisibility).filter(
      (column) => columnVisibility[column] === false
    );
    setHiddenColumns(hidden);
  }, [columnVisibility]);

  function handleAddFilter(filter: PlayerFilter) {
    setFilters((prevFilters) => [
      ...prevFilters,
      { ...filter, selectedOperator: filter.availableOperators[0] },
    ]);
  }

  const handleRemoveFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleUpdateFilter = (index: number, values: object) => {
    const filter = filters[index];
    const newFilters = [...filters];
    newFilters[index] = {
      ...filter,
      ...values,
    };

    setFilters(newFilters);
  };

  function handleSubmit() {
    const newSearchParams = filtersToSearchParams(filters);

    // get search params that aren't part of the filters and make sure they are included
    // when setting search params so we don't overwrite params from another page
    const nonFilterSearchParams = Object.keys(
      Object.fromEntries(searchParams)
    ).reduce<Record<string, string[]>>((acc, key) => {
      const [filterKey] = key.split(".");
      const filter = availableFilters
        .map((filterGroup) => filterGroup.filters)
        .flat()
        .find((filter) => filter.key === filterKey);
      if (!filter) {
        acc[key] = searchParams.getAll(key);
      }
      return acc;
    }, {});

    setSearchParams({
      ...nonFilterSearchParams,
      ...newSearchParams,
      hidden: hiddenColumns.join(","),
      page: "1",
    });

    onSubmit();
  }

  return (
    <Flex direction="down" gap="sm" align="start">
      <ColumnFilters
        columns={columns}
        columnVisibility={hiddenColumns.reduce<VisibilityState>(
          (acc, column) => {
            acc[column] = false;
            return acc;
          },
          {}
        )}
        onChange={(column, checked) => {
          setHiddenColumns((prev) =>
            checked
              ? [...prev, column]
              : prev.filter((hiddenColumn) => hiddenColumn !== column)
          );
        }}
      />

      <Flex
        direction="right"
        justify="between"
        align="center"
        className="mt-4 w-full"
      >
        <Text heading size="xl">
          Filters
        </Text>
        {filters.length > 0 && (
          <Button variant="link" onClick={() => setFilters([])}>
            <Text size="sm">Clear Filters</Text>
          </Button>
        )}
      </Flex>

      <Flex direction="down" gap="xs">
        {filters.map((filter, i) => (
          <Flex
            direction="right"
            gap="sm"
            align="center"
            wrap={false}
            key={`${filter.key}-${i}`}
            className="pt-4"
          >
            <Text size="sm">{filter.label}</Text>
            <Select
              value={filter.selectedOperator}
              onValueChange={(newFilterType) =>
                handleUpdateFilter(i, { selectedOperator: newFilterType })
              }
            >
              {filter.availableOperators?.map((filterType) => (
                <SelectOption key={filterType} value={filterType}>
                  {labelForOperatorType(filterType)}
                </SelectOption>
              ))}
            </Select>

            <Flex>
              {filter.renderInputField(filter.selectedValue, (value) =>
                handleUpdateFilter(i, { selectedValue: value })
              )}
            </Flex>

            <Button
              variant="ghost"
              size="flush"
              onClick={() => handleRemoveFilter(i)}
            >
              <CircleXIcon size={18} className="text-muted-500" />
            </Button>
          </Flex>
        ))}
      </Flex>
      <Popover open={filterPopoverOpen} onOpenChange={setFilterPopoverOpen}>
        <Flex direction="right" gap="sm" className="pt-4">
          <PopoverTrigger asChild>
            <Button variant="muted">
              <PlusIcon size={18} className="mr-2" /> Add filter
            </Button>
          </PopoverTrigger>
          <Button onClick={handleSubmit}>Update</Button>
        </Flex>
        <PopoverContent className="p-0">
          <Command>
            <CommandInput placeholder="Filters" />
            <CommandList>
              <CommandEmpty>"No results found."</CommandEmpty>
            </CommandList>
            <CommandList>
              {availableFilters.map((filterGroup) => (
                <CommandGroup
                  key={filterGroup.groupLabel}
                  heading={filterGroup.groupLabel}
                >
                  {filterGroup.filters.map((filter) => (
                    <CommandItem
                      key={filter.label}
                      className="px-5"
                      onSelect={() => {
                        setFilterPopoverOpen(false);
                        handleAddFilter(filter);
                      }}
                    >
                      {filter.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

function LeagueSelectField({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const { leagues, loading } = useLeagues();

  const options = useMemo(() => {
    return (
      leagues?.map((league) => ({
        key: league.id.toString(),
        searchKey: league.name,
        name: league.name,
      })) || []
    );
  }, [leagues]);

  if (loading) {
    return <LoaderCircleIcon size={18} className="animate-spin" />;
  }

  return (
    <Filter
      className="bg-white"
      value={value?.split(",").filter((listItem) => listItem.length > 0) ?? []}
      onChange={(selectedKeys: string[]) => {
        onChange?.(selectedKeys.join(",").trim());
      }}
      options={options}
    />
  );
}
