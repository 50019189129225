import {
  DropdownMenu,
  DropdownMenuTrigger,
  Button,
  Flex,
  Separator,
  Badge,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
} from "@suns/design-system";
import { PlusCircleIcon } from "@suns/design-system/icons";
import { PlayerColumn } from "../players-listings";
import { VisibilityState } from "@tanstack/react-table";
import { useEffect, useState } from "react";

export default function ColumnFilters({
  columns,
  columnVisibility,
  onChange,
}: {
  columns: PlayerColumn[];
  columnVisibility: VisibilityState;
  onChange: (column: string, checked: boolean) => void;
}) {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  useEffect(() => {
    const hidden = Object.keys(columnVisibility).filter(
      (column) => columnVisibility[column] === false
    );
    setHiddenColumns(hidden);
  }, [columnVisibility]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="dashed" className="bg-white">
          <Flex direction="right" gap="sm" align="center">
            <PlusCircleIcon size={18} />
            Columns
            <Separator orientation="vertical" className="mx-2 h-4" />
            <Badge className="rounded-sm">
              {
                Object.values(columns).filter(
                  (column) =>
                    column.enableHiding !== false &&
                    !hiddenColumns.includes(column.accessorKey)
                ).length
              }{" "}
              Selected
            </Badge>
          </Flex>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-60">
        {columns
          .filter((column) => column.enableHiding !== false)
          .map((column, i) => {
            return (
              <DropdownMenuCheckboxItem
                key={`${column.id}.${i}`}
                checked={!hiddenColumns.includes(column.accessorKey)}
                onClick={(e) => {
                  const checked = hiddenColumns.includes(column.accessorKey);
                  onChange(column.accessorKey, !checked);
                  e.preventDefault();
                }}
              >
                {column.header}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
