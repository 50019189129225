/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameRow } from './GameRow';
import type { PlayerRow } from './PlayerRow';
import type { ReportApolloGrade } from './ReportApolloGrade';
import type { ReportPlayerAttribute } from './ReportPlayerAttribute';
import type { ReportScore } from './ReportScore';
export type ReportResponseItem = {
    id: number;
    playerId: number;
    player: PlayerRow;
    teamId: number | null;
    games: Array<GameRow>;
    status: ReportResponseItem.status;
    level: ReportResponseItem.level;
    type: ReportResponseItem.type;
    scoutLocation: ReportResponseItem.scoutLocation | null;
    position: ReportResponseItem.position | null;
    role: ReportResponseItem.role | null;
    teamFit: ReportResponseItem.teamFit | null;
    teamFitNotes: string | null;
    offensiveNotes: string | null;
    defensiveNotes: string | null;
    otherNotes: string | null;
    authorUsername: string;
    authorName: string;
    didNotPlay: boolean | null;
    phaseReportId: number | null;
    apolloGrades: Array<ReportApolloGrade> | null;
    playerAttributes: Array<ReportPlayerAttribute>;
    scores: Array<ReportScore>;
    reports: Array<ReportResponseItem>;
    phase: ReportResponseItem.phase;
    updatedAt: string;
    createdAt: string;
    deletedAt: string | null;
};
export namespace ReportResponseItem {
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum type {
        PHASE = 'PHASE',
        GENERAL = 'GENERAL',
    }
    export enum scoutLocation {
        ON_SITE = 'ON_SITE',
        VIDEO = 'VIDEO',
    }
    export enum position {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum role {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERNAL_DEFENDER = 'INTERNAL_DEFENDER',
    }
    export enum teamFit {
        YES = 'YES',
        NO = 'NO',
        NA = 'NA',
    }
    export enum phase {
        PHASE_24_25_0 = 'PHASE_24_25_0',
        PHASE_24_25_1 = 'PHASE_24_25_1',
        PHASE_24_25_2 = 'PHASE_24_25_2',
        PHASE_24_25_3 = 'PHASE_24_25_3',
        PHASE_24_25_4 = 'PHASE_24_25_4',
        PHASE_24_25_5 = 'PHASE_24_25_5',
        PHASE_24_25_6 = 'PHASE_24_25_6',
    }
}

