import { StatName } from "@/shared/const/glossary";
import { Flex, Select, SelectOption } from "@suns/design-system";

export function PlayerStatSelector({
  statsMeasureType,
  setStatsMeasureType,
  statsPerMode,
  setStatsPerMode,
}: {
  statsMeasureType: string;
  setStatsMeasureType: (value: string) => void;
  statsPerMode: string;
  setStatsPerMode: (value: string) => void;
}) {
  return (
    <Flex gap="sm">
      <Select
        value={statsMeasureType}
        onValueChange={(value) => setStatsMeasureType(value)}
        className="bg-white"
      >
        <SelectOption value="Advanced">{StatName.Advanced}</SelectOption>
        <SelectOption value="Base">{StatName.BoxScore}</SelectOption>
        <SelectOption value="Defense">{StatName.Defense}</SelectOption>
        <SelectOption value="Four Factors">{StatName.FourFactors}</SelectOption>
        <SelectOption value="Misc">{StatName.Misc}</SelectOption>
        <SelectOption value="Opponent">{StatName.Opponent}</SelectOption>
        <SelectOption value="Scoring">{StatName.Scoring}</SelectOption>
        <SelectOption value="Usage">{StatName.Usage}</SelectOption>
      </Select>
      <Select
        value={statsPerMode}
        onValueChange={(value) => setStatsPerMode(value)}
        className="bg-white"
      >
        <SelectOption value="PerGame">{StatName.PerGame}</SelectOption>
        <SelectOption value="Totals">{StatName.Totals}</SelectOption>
        <SelectOption value="Per100Possessions">
          {StatName.Per100Possessions}
        </SelectOption>
        <SelectOption value="Per100Plays">{StatName.Per100Plays}</SelectOption>
        <SelectOption value="PerPossession">
          {StatName.PerPossession}
        </SelectOption>
        <SelectOption value="Per48">{StatName.Per48}</SelectOption>
        <SelectOption value="Per40">{StatName.Per40}</SelectOption>
        <SelectOption value="Per36">{StatName.Per36}</SelectOption>
        <SelectOption value="PerMinute">{StatName.PerMinute}</SelectOption>
        <SelectOption value="PerPlay">{StatName.PerPlay}</SelectOption>
      </Select>
    </Flex>
  );
}
