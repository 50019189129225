import { useAccount } from "@/shared/hooks/useAccount";
import {
  Card,
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { myReportsLoader } from "./loaders/my-reports-loader";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { SunsApiError } from "@suns/api";
import { defaultFilterValues } from "@/shared/const";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { FilterValues } from "./all-reports-listings";
import { useSearchParams } from "react-router-dom";
import ReportFilters from "./components/ReportFilters";
import { ReportsList } from "@/components/ReportsList/ReportsList";

const PAGE_SIZE = 20;

export function ReportsListings() {
  const account = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamIds: searchParams.getAll("teamIds")?.map(Number),
    playerId: Number(searchParams.getAll("playerId")) || undefined,
    status: (searchParams.get("status") as FilterValues["status"]) || undefined,
    reportsPageNumber:
      Number(searchParams.get("reportsPageNumber")) || undefined,
  });

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const [activeTab, setActiveTab] = useState(
    filterValues.status || "published"
  );
  const [publishedPage, setPublishedPage] = useState(
    filterValues.reportsPageNumber || 0
  );
  const [unPublishedPage, setUnpublishedPage] = useState(
    filterValues.reportsPageNumber || 0
  );

  const {
    loading: publishedLoading,
    response: publishedReponse,
    refresh: refreshPublished,
    error: publishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerId: filterValues.playerId,
  });

  const {
    loading: unPublishedLoading,
    response: unPublishedReponse,
    refresh: refreshUnPublished,
    error: unPublishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.UNPUBLISHED,
    offset: unPublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamIds: filterValues.teamIds,
    playerId: filterValues.playerId,
  });

  if (publishedError) {
    throw new SunsApiError("Error loading the published reports.", {
      cause: publishedError,
    });
  }

  if (unPublishedError) {
    throw new SunsApiError("Error loading the unpublished reports.", {
      cause: unPublishedError,
    });
  }

  const currentPublishedPage = Math.floor(
    (publishedReponse?.offset || 0) / (publishedReponse?.limit || 1)
  );
  if (publishedPage !== currentPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshPublished();
  }

  const currentUnPublishedPage = Math.floor(
    (unPublishedReponse?.offset || 0) / (unPublishedReponse?.limit || 1)
  );
  if (unPublishedPage !== currentUnPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshUnPublished();
  }

  const handleChangeFilterValues = (value: FilterValues) => {
    const { teamIds, playerId, status, reportsPageNumber } = filterValues;

    setPublishedPage(0);
    setUnpublishedPage(0);
    setFilterValues({ teamIds, playerId, status, reportsPageNumber, ...value });

    const searchParams = {
      teamIds,
      playerId,
      status,
      reportsPageNumber,
      ...value,
    };

    const filteredSearchParams = Object.entries(searchParams)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => v !== undefined)
      .reduce((acc, [key, values]) => {
        if (values) {
          if (Array.isArray(values)) {
            values.forEach((value) => acc.append(key, value.toString()));
          } else {
            acc.append(key, values.toString());
          }
        }
        return acc;
      }, new URLSearchParams());

    setSearchParams(filteredSearchParams);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  const handleSetPage = (page: number) => {
    if (activeTab === "published") {
      setPublishedPage(page - 1);
    } else {
      setUnpublishedPage(page - 1);
    }
    const params = new URLSearchParams();
    const { teamIds, playerId } = filterValues;
    Object.entries({
      teamIds,
      playerId,
      status: filterValues.status || "published",
      reportsPageNumber: (page - 1).toString(),
    }).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v.toString()));
      } else if (value) {
        params.append(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
    if (tab === "published") {
      setPublishedPage(0);
    } else {
      setUnpublishedPage(0);
    }
    const params = new URLSearchParams();
    const { teamIds, playerId, status } = filterValues;

    Object.entries({
      teamIds,
      playerId,
      status,
      reportsPageNumber: "0",
    }).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v.toString()));
      } else if (value) {
        params.append(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  return (
    <Card
      header={
        <Text size="xl" heading>
          My Reports
        </Text>
      }
    >
      <Tabs onValueChange={handleChangeTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          <Flex direction="down" gap="md">
            <ReportFilters
              type="myReportsIntel"
              onChange={handleChangeFilterValues}
              onClear={handleClear}
              filterValues={filterValues}
            />
            <ReportsList
              loading={publishedLoading}
              response={
                publishedReponse || {
                  reports: [],
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                  sortColumn: "createdAt",
                  sortDir: "DESC",
                }
              }
              setPage={handleSetPage}
            />
          </Flex>
        </TabsContent>
        <TabsContent value="unpublished">
          <Flex direction="down" gap="md">
            <ReportFilters
              type="myReportsIntel"
              onChange={handleChangeFilterValues}
              onClear={handleClear}
              filterValues={filterValues}
            />
            <ReportsList
              loading={unPublishedLoading}
              response={
                unPublishedReponse || {
                  reports: [],
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                  sortColumn: "createdAt",
                  sortDir: "DESC",
                }
              }
              setPage={handleSetPage}
            />
          </Flex>
        </TabsContent>
      </Tabs>
    </Card>
  );
}
