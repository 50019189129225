import { viewPlayerLoader } from "./loaders/view-player-loader";
import { useAsync } from "@/shared/hooks";
import { ApolloLeagueId, SunsApiError } from "@suns/api";
import {
  Card,
  Flex,
  SkeletonText,
  SkeletonHeading,
  Text,
  Container,
  Separator,
  cn,
  Grid,
} from "@suns/design-system";
import {
  Page,
  PlayerHeaderSkeleton,
  PlayerHeadshot,
  TeamLogo,
} from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  formatAge,
  formatInchesToFeet,
  formatWeight,
} from "@/shared/utils/helper-functions";
import { PlayerInfoSection } from "./components/PlayersInfoSection";
import { ApolloGradeValueLabels } from "../reports/reports-const";
import {
  PlayerMetadataPositionLabels,
  PlayerMetadataRoleLabels,
} from "./players-const";

type PlayerDetailItem = {
  label: string;
  value: string;
  onClick?: () => void;
};

export function PlayersView() {
  const { playerId } = useParams();
  const navigate = useNavigate();
  const { response, loading, error } = useAsync(viewPlayerLoader, {
    playerId: Number(playerId),
  });

  return (
    <Page
      title={
        response
          ? `${response?.player.firstName} ${response?.player.lastName}`
          : "View Player"
      }
      loading={loading}
      loadingState={<PlayerViewLoading />}
      className="mt-3 lg:mt-1"
      render={() => {
        if (error) {
          throw new SunsApiError("Error loading report", { cause: error });
        }

        const { player } = response!;

        const playerMetadata = {
          Grade: player.metadata[0]?.hybridGradeCurrent
            ? ApolloGradeValueLabels[
                player.metadata[0]?.hybridGradeCurrent
                  .value as keyof typeof ApolloGradeValueLabels
              ]
            : "--",
          "Remaining Capacity": player.metadata[0]?.hybridGradeRemainingCapacity
            ? ApolloGradeValueLabels[
                player.metadata[0]?.hybridGradeRemainingCapacity
                  .value as keyof typeof ApolloGradeValueLabels
              ]
            : "--",
          Position:
            PlayerMetadataPositionLabels[
              player.metadata[0]
                ?.hybridPosition as keyof typeof PlayerMetadataPositionLabels
            ] ?? "--",
          Role:
            PlayerMetadataRoleLabels[
              player.metadata[0]
                ?.hybridRole as keyof typeof PlayerMetadataRoleLabels
            ] ?? "--",
        };

        const countryName = player.homeCountryCode?.slice(0, -1) ?? null;

        const playerCountry = countryName
          ? new Intl.DisplayNames(["en"], { type: "region" }).of(countryName)
          : player.homeCountryCode;

        return (
          <Container size="xl">
            <Flex direction="down" gap="md">
              <Card className="overflow-hidden px-0 pb-4 pt-2">
                <Flex gap="md">
                  {/* Desktop only */}
                  <Flex
                    className="relative w-44 overflow-hidden max-sm:hidden"
                    align="end"
                  >
                    {player.currentTeams?.[0]?.domesticLeagueId !==
                    ApolloLeagueId.NCAA ? (
                      <div className="z-2 absolute -bottom-20 -left-20 size-56 opacity-15">
                        <TeamLogo
                          nbaTeamId={
                            player.currentTeams?.[0]?.nbaId ?? undefined
                          }
                          apolloLeagueId={
                            player.currentTeams?.[0]?.domesticLeagueId
                          }
                          image={player.currentTeams?.[0]?.image}
                          className="h-full w-full"
                        />
                      </div>
                    ) : null}
                    <PlayerHeadshot
                      src={player.image}
                      nbaPlayerId={player.nbaId}
                      apolloLeagueId={
                        player.currentTeams?.[0]?.domesticLeagueId
                      }
                      size="xl"
                      variant="profile"
                      className="ml-4"
                    />
                  </Flex>
                  <Flex gap="sm" direction="down" className="mt-2">
                    <Flex
                      align="center"
                      gap="lg"
                      className="relative overflow-hidden max-sm:-ml-6 max-sm:pl-9"
                    >
                      <div className="sm:hidden">
                        {player.currentTeams?.[0]?.domesticLeagueId !==
                        ApolloLeagueId.NCAA ? (
                          <div className="z-1 absolute -bottom-14 -left-6 size-48 opacity-15 sm:hidden">
                            <TeamLogo
                              nbaTeamId={
                                player.currentTeams?.[0]?.nbaId ?? undefined
                              }
                              apolloLeagueId={
                                player.currentTeams?.[0]?.domesticLeagueId
                              }
                              image={player.currentTeams?.[0]?.image}
                              className="h-full w-full"
                            />
                          </div>
                        ) : null}
                        <PlayerHeadshot
                          src={player.image}
                          nbaPlayerId={player.nbaId}
                          apolloLeagueId={
                            player.currentTeams?.[0]?.domesticLeagueId
                          }
                          size="xl"
                          variant="profile"
                        />
                      </div>
                      <Flex gap="xs" direction="down">
                        <Text size="2xl" heading>
                          {player.firstName} {player.lastName}
                        </Text>
                        <Flex direction="right" gap="xs" align="center">
                          {player.nbaId || player.currentTeams?.[0]?.image ? (
                            <TeamLogo
                              image={player.currentTeams?.[0]?.image}
                              nbaTeamId={
                                player.currentTeams?.[0]?.nbaId ?? undefined
                              }
                              apolloLeagueId={
                                player.currentTeams?.[0]?.domesticLeagueId
                              }
                              size="xxs"
                            />
                          ) : (
                            <div className="h-3" />
                          )}
                          <Text size="xs">
                            {player.currentTeams?.[0]?.fullName ?? "Free Agent"}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex gap="lg" className="mb-2 max-sm:hidden">
                      {Object.entries(playerMetadata).map(([key, value]) => (
                        <div key={key}>
                          <Text size="xs" muted heading>
                            {key}
                          </Text>
                          <Text size="xl" heading>
                            {value}
                          </Text>
                        </div>
                      ))}
                    </Flex>

                    {/* Mobile only */}
                    <Flex
                      direction="right"
                      gap="md"
                      className="my-2 w-full pl-5 sm:hidden"
                    >
                      <Flex direction="down" className="w-full">
                        <Grid columns="2" gap="md">
                          {Object.entries(playerMetadata).map(
                            ([key, value]) => (
                              <div key={key}>
                                <Text size="xs" muted heading>
                                  {key}
                                </Text>
                                <Text size="xl" heading>
                                  {value}
                                </Text>
                              </div>
                            )
                          )}
                        </Grid>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Separator className="mb-4 h-[2px] bg-gray-200" />
                <Grid columns="md:3" gap="lg" className="pl-5">
                  <PlayerDetails
                    title="Player Info"
                    items={[
                      {
                        label: "Birthdate",
                        value: player.birthDate
                          ? `${dayjs(player.birthDate).format("MM/DD/YYYY")} (${formatAge(player.birthDate)})`
                          : "--",
                      },
                      {
                        label: "Birthplace",
                        value: `${player.homeTown ? player.homeTown : "--"}${
                          player.homeState ? ", " + player.homeState : ""
                        }`,
                      },
                      {
                        label: "Pre-Draft Team",
                        value: "--",
                      },
                      {
                        label: "High School",
                        value: player.highschool || "--",
                      },
                      {
                        label: "Nationality",
                        value: playerCountry ?? "--",
                      },
                      {
                        label: "Years of Service",
                        value:
                          player.yearsOfService !== null
                            ? player.yearsOfService === 0
                              ? "Rookie"
                              : `${player.yearsOfService} years`
                            : "--",
                      },
                    ]}
                  />
                  <PlayerDetails
                    title="Status"
                    items={[
                      {
                        label: "Level",
                        value: player.level ?? "--",
                      },
                      {
                        label: "Agent",
                        value: player.agent?.name ?? "--",
                        onClick: () => {
                          navigate(`/players/agents/${player.agent?.id}`);
                        },
                      },
                    ]}
                  />
                  <PlayerDetails
                    title="Measurements"
                    items={[
                      {
                        label: "Height",
                        value: player.height
                          ? formatInchesToFeet(player.height)
                          : "--",
                      },
                      {
                        label: "Weight",
                        value: player.weight
                          ? formatWeight(player.weight)
                          : "--",
                      },
                    ]}
                  />
                </Grid>
              </Card>
              <Card>
                <PlayerInfoSection player={player} />
              </Card>
            </Flex>
          </Container>
        );
      }}
    />
  );
}

function PlayerViewLoading() {
  return (
    <Container size="xl">
      <Flex direction="down" gap="md">
        <Card>
          <PlayerHeaderSkeleton />
        </Card>
        <Card>
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="md">
              <SkeletonHeading size="3xl" className="w-36" />
              <SkeletonHeading size="3xl" className="w-28" />
            </Flex>
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
}

function PlayerDetails({
  title,
  items,
}: {
  title: string;
  items: PlayerDetailItem[];
}) {
  return (
    <Flex direction="down" gap="sm">
      <Text size="md" heading variant="muted">
        {title}
      </Text>
      <Flex gap="lg">
        <Flex
          direction="down"
          gap="sm"
          className=" whitespace-nowrap max-md:w-28 max-sm:w-28"
        >
          {items.map((item, index) => (
            <Text size="sm" key={index} muted>
              {item.label}
            </Text>
          ))}
        </Flex>
        <Flex direction="down" gap="sm">
          {items.map((item, index) => {
            const isAgent = item.label === "Agent";
            return (
              <Text
                size="sm"
                key={index}
                heading
                onClick={item.onClick ? item.onClick : undefined}
                className={cn(
                  isAgent &&
                    isAgent &&
                    item.value !== "--" &&
                    "cursor-pointer text-primary"
                )}
              >
                {item.value}
              </Text>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
