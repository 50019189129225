import { useState } from "react";
import { PlayerCacheItem, usePlayerTeamSearch } from "@/shared/hooks";
import { Filter, FilterOption, Skeleton } from "@suns/design-system";
import React from "react";

interface PlayerFilterProps {
  value: string[];
  onChange: (keys: string[], items: PlayerCacheItem[]) => void;
  className?: string;
}

export const PlayerFilter = React.forwardRef<
  HTMLButtonElement,
  PlayerFilterProps
>(({ value, onChange, className }, ref) => {
  const [searchValue, setSearchValue] = useState("");
  const { results, lookupMap, loading } = usePlayerTeamSearch(searchValue, {
    playerOnly: true,
    limit: 30,
  });

  if (loading) {
    return <Skeleton className="h-10 w-40" />;
  }

  const options = [
    ...value.map((id) => {
      const item = lookupMap?.get(`player-${id}`);
      if (!item) {
        throw new Error(`Player not found: ${id}`);
      }
      return {
        ...item,
        key: id,
        name: item.display,
        badgeValue: item.teamCode,
      };
    }),
    ...results.reduce<(PlayerCacheItem & FilterOption)[]>((acc, result) => {
      if (value.includes(`${result.id}`)) {
        return acc;
      }
      acc.push({
        ...result,
        key: `${result.id}`,
        name: result.display,
        badgeValue: result.teamCode,
      });
      return acc;
    }, []),
  ];

  return (
    <Filter
      innerRef={ref}
      shouldFilter={false}
      className={className}
      title="Add Player"
      value={value}
      onChange={onChange}
      options={options}
      onSearchChange={setSearchValue}
    />
  );
});
