import { PlayerHeadshot } from "@/components/PlayerHeadshot/PlayerHeadshot";
import { Flex, Skeleton, SkeletonText, Text } from "@suns/design-system";
import { TeamLogo } from "@/components/TeamLogo/TeamLogo";
import { ApolloLeagueId } from "@suns/api";
import { PlayerRow } from "@/swagger";
import { PlayerMeasurements } from "../PlayerMeasurements/PlayerMeasurements";

interface PlayerHeaderProps {
  player: PlayerRow;
  teamNbaId?: number;
  teamName?: string;
  teamImage?: string;
  leagueId?: ApolloLeagueId;
}

export function PlayerHeader({
  player,
  teamImage,
  teamNbaId,
  teamName,
  leagueId,
}: PlayerHeaderProps) {
  return (
    <Flex gap="lg" align="center" className="gap-y-sm" wrap>
      <Flex gap="md" align="center">
        <PlayerHeadshot
          key={player.image}
          src={player.image}
          nbaPlayerId={player.nbaId}
          apolloLeagueId={leagueId}
          size="lg"
        />

        <Flex direction="down">
          <Text size="xl" heading>
            {player.firstName} {player.lastName}
          </Text>
          <Flex direction="right" gap="xs" align="center">
            {player.nbaId || teamImage ? (
              <TeamLogo
                image={teamImage}
                nbaTeamId={teamNbaId}
                apolloLeagueId={leagueId}
                size="xxs"
              />
            ) : (
              <div className="h-3" />
            )}
            <Text size="xs">{teamName ?? "Free Agent"}</Text>
          </Flex>
        </Flex>
      </Flex>
      <PlayerMeasurements player={player} />
    </Flex>
  );
}

export function PlayerHeaderSkeleton() {
  return (
    <Flex gap="lg" className="content-center">
      {/* <PlayerHeadshot playerId={nbaId} size="lg" /> */}
      <Skeleton className="aspect-square h-24 w-auto rounded-full" />
      <Flex direction="down" gap="sm">
        {/* <SkeletonHeading /> */}
        <SkeletonText rows={1} className="h-6 w-64" />
        <SkeletonText rows={1} className="h-4 w-40" />

        <Flex direction="right" gap="md">
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
        </Flex>
      </Flex>
    </Flex>
  );
}
