import { useEffect, useMemo, useState } from "react";
import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandEmpty,
  Command,
} from "cmdk";
import {
  Badge,
  cn,
  Flex,
  Input,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Separator,
} from "@suns/design-system";
import {
  PlayerCacheItem,
  TeamCacheItem,
  usePlayerTeamSearch,
} from "@/shared/hooks";

interface PlayerTeamSearchProps {
  defaultId?: { id: number; type: "team" | "player" };
  className?: string;
  playerOnly?: boolean;
  teamOnly?: boolean;
  onChange: (id: number | null, type: "team" | "player" | null) => void;
}

export function PlayerTeamSearch({
  defaultId,
  onChange,
  teamOnly,
  playerOnly,
}: PlayerTeamSearchProps) {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { results, lookupMap, loading } = usePlayerTeamSearch(searchValue, {
    teamOnly,
    playerOnly,
  });
  const defaultSelectedItem = useMemo(() => {
    if (!defaultId || !lookupMap) {
      return;
    }
    return lookupMap.get(`${defaultId.type}-${defaultId.id}`);
  }, [lookupMap, defaultId]);
  const [selectedItem, setSelectedItem] = useState<
    TeamCacheItem | PlayerCacheItem | undefined
  >(defaultSelectedItem);

  const placeholder = `Search for a ${teamOnly ? "team" : playerOnly ? "player" : "player or team"}`;

  const onSelectItem = (
    type: "team" | "player",
    item: TeamCacheItem | PlayerCacheItem
  ) => {
    onChange(item.id, type);
    setSearchValue("");
  };

  const getNextIndex = (
    e: React.KeyboardEvent<HTMLInputElement>,
    hoveredIndex: number | null,
    length: number
  ): number => {
    if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
      return hoveredIndex === null
        ? length - 1
        : (hoveredIndex - 1 + length) % length;
    } else {
      return hoveredIndex === null ? 0 : (hoveredIndex + 1) % length;
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (open) {
      if (e.key === "Tab" || e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        if (results.length > 0) {
          const nextIndex = getNextIndex(e, hoveredIndex, results.length);
          setHoveredIndex(nextIndex);
        }
      } else if (e.key === "Enter" && hoveredIndex !== null) {
        e.preventDefault();
        const selectedPlayerOrTeam = results[hoveredIndex];
        onSelectItem(selectedPlayerOrTeam.type, selectedPlayerOrTeam);
      }
    }
  };

  useEffect(() => {
    setSelectedItem(defaultSelectedItem);
  }, [defaultSelectedItem]);

  const isOpen = open && searchValue.length >= 1;

  let inputValue = "";
  if (searchValue !== "") {
    inputValue = searchValue;
  } else if (selectedItem) {
    inputValue =
      selectedItem.type == "team"
        ? selectedItem.teamName
        : `${selectedItem.lastName}, ${selectedItem.firstName}`;
  }

  return (
    <Flex align="center" className="max-w-full">
      <Popover open={isOpen} onOpenChange={setOpen}>
        <Command shouldFilter={false} className="w-full">
          <PopoverAnchor>
            <Command.Input
              asChild
              value={inputValue}
              onValueChange={(value) => {
                setSearchValue(value);
                if (value === "") {
                  setSelectedItem(undefined);
                  onChange(null, null);
                }
              }}
              onKeyDown={onKeyDown}
              onFocus={() => setOpen(true)}
            >
              <Input type="text" placeholder={placeholder} />
            </Command.Input>
          </PopoverAnchor>
          {!isOpen && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            avoidCollisions={false}
            onOpenAutoFocus={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              <CommandGroup>
                <CommandEmpty className="px-3 py-2">
                  {loading ? "Loading players and teams..." : "No items found."}
                </CommandEmpty>
                {results.map((item, index) => (
                  <CommandItem
                    key={item.id}
                    value={String(item.id)}
                    onKeyDown={(e) => setOpen(e.key !== "Escape")}
                    onSelect={() => {
                      onSelectItem(item.type, item);
                    }}
                    className={cn(
                      "cursor-pointer",
                      hoveredIndex == index && "bg-gray-200"
                    )}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <Flex
                      gap="sm"
                      align="center"
                      className="px-3 py-2 text-sm md:text-base"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      {item.type == "team"
                        ? item.teamName
                        : `${item.lastName}, ${item.firstName}`}
                      <Flex gap="sm" align="center" justify="end">
                        <div className="w-14">
                          {item.type == "player" ? (
                            <Badge muted>{item.teamCode || "FA"}</Badge>
                          ) : null}
                        </div>
                      </Flex>
                    </Flex>
                    <Separator
                      className={cn(
                        "my-0",
                        index == results.length - 1 && "hidden"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </Flex>
  );
}
