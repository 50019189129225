import {
  Card,
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Button,
  Text,
} from "@suns/design-system";

import GameSelection from "./components/GameSelection";
import LeagueSelect from "./components/LeagueSelect";
import { DatePicker } from "@suns/design-system/src/components/DatePicker/DatePicker";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useReportSelect } from "./hooks/useReportSelect";
import { ApolloLeagueId, SunsApiError } from "@suns/api";
import { Page, PlayerTeamSearch } from "@/components";
import { URL } from "@/shared/utils/route-constant";
import { AvailableLeagues } from "@/shared/const";
import { GameRow } from "@suns/api/generated-client/apollo";

enum ReportTypeValue {
  GAME = "Game",
  PLAYER = "Player",
}

export function ReportsCreate() {
  const [date, setDate] = useState<Date>(new Date());
  const [selectedGame, setSelectedGame] = useState<GameRow>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<ReportTypeValue>(
    ReportTypeValue.GAME
  );

  const { leagues, selectedLeague, setSelectedLeague, games, loading, error } =
    useReportSelect(date);
  const navigate = useNavigate();

  function handleTabChange(newTab: string) {
    if (newTab !== activeTab) {
      setActiveTab(newTab as ReportTypeValue);
      setSearchParams({ reportType: newTab });
    }
  }
  const reportTypeFromParams = searchParams.get("reportType");

  const handleSelectGame = (game: GameRow) => {
    setSelectedGame(game);
    navigate(`game/${game.id}`);
  };

  if (error) {
    throw new SunsApiError("Unable to load games.", {
      cause: error,
    });
  }

  const handleSelectPlayer = (playerId: number | null) => {
    if (!playerId) return;
    navigate(`player/${playerId}`);
  };

  const filteredLeagues =
    leagues
      ?.filter((league) => AvailableLeagues.includes(league.id))
      .sort((league1, league2) => {
        const order = [
          ApolloLeagueId.NBA,
          ApolloLeagueId.NCAA,
          ApolloLeagueId.GLEAGUE,
        ];
        const league1Index = order.indexOf(league1.id);
        const league2Index = order.indexOf(league2.id);
        if (league1Index === -1 && league2Index === -1) return 0;
        if (league1Index === -1) return 1;
        if (league2Index === -1) return -1;
        return league1Index - league2Index;
      }) || [];

  return (
    <Page title="Create Report">
      <Card className="h-full">
        <Tabs
          defaultValue={reportTypeFromParams ?? ReportTypeValue.GAME}
          className="w-full"
          onValueChange={handleTabChange}
        >
          <Flex justify="between" gap="sm" wrap>
            <TabsList>
              <TabsTrigger value={ReportTypeValue.GAME}>
                <span>{ReportTypeValue.GAME}</span>
              </TabsTrigger>
              <TabsTrigger value={ReportTypeValue.PLAYER}>
                <span>{ReportTypeValue.PLAYER}</span>
              </TabsTrigger>
            </TabsList>
            <Flex gap="sm" wrap>
              <Button size="sm" variant="muted" asChild>
                <Link
                  to={`/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_ASESSMENTS.path}`}
                >
                  Player Assessments
                </Link>
              </Button>

              <Button size="sm" asChild>
                <Link
                  to={`/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_PHASE_REPORT.path}`}
                >
                  Phase Reports
                </Link>
              </Button>
            </Flex>
          </Flex>
          <TabsContent className="mt-6" value={ReportTypeValue.GAME}>
            <Flex gap="md">
              <DatePicker date={date} setDate={setDate} />
              <LeagueSelect
                leagueList={filteredLeagues}
                selectedLeague={selectedLeague}
                setSelectedLeague={setSelectedLeague}
              />
            </Flex>
            <GameSelection
              selectedGame={selectedGame}
              handleSelectGame={handleSelectGame}
              games={games}
              loading={loading}
            />
          </TabsContent>
          <TabsContent className="mt-6" value={ReportTypeValue.PLAYER}>
            <Text className="mb-2" heading>
              Create a report for a player
            </Text>
            <div className="w-[400px] xs:max-w-full">
              <PlayerTeamSearch playerOnly onChange={handleSelectPlayer} />
            </div>
          </TabsContent>
        </Tabs>
      </Card>
    </Page>
  );
}
