import { ReportApolloGrade } from "@suns/api/generated-client/apollo";

export function apolloGradeColor(grade: ReportApolloGrade.value) {
  switch (grade) {
    case ReportApolloGrade.value.FRANCHISE:
      return "bg-amber-400 border-amber-400";
    case ReportApolloGrade.value.ALL_STAR_PLUS:
      return "bg-amber-500 border-amber-500";
    case ReportApolloGrade.value.ALL_STAR:
      return "bg-orange-400 border-orange-400";
    case ReportApolloGrade.value.STARTER:
      return "bg-orange-500 border-orange-500";
    case ReportApolloGrade.value.TOP_ROTATION:
      return "bg-orange-600 border-orange-600";
    case ReportApolloGrade.value.ROTATION:
      return "bg-fuchsia-700 border-fuchsia-700";
    case ReportApolloGrade.value.ROSTER:
      return "bg-purple-700 border-purple-700";
    case ReportApolloGrade.value.MINOR_LEAGUE:
      return "bg-purple-900 border-purple-900";
  }
}
