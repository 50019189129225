import { useAccount, useNavigateLogin } from "@/shared/hooks";

interface ProtectedProps {
  children: React.ReactNode;
}
export function Protected({ children }: ProtectedProps) {
  const { isAuthenticated, hasDefaultAccess } = useAccount();
  const navigateLogin = useNavigateLogin();

  if (isAuthenticated && hasDefaultAccess) {
    return children;
  } else {
    navigateLogin();
    return null;
  }
}

export default Protected;
