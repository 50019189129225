import { useAccount, useAsync, useNavigateLogin } from "@/shared/hooks";
import { phaseReportLoader } from "./loaders/create-phase-report-loader";
import {
  Badge,
  Button,
  Card,
  cn,
  Flex,
  Select,
  SelectOption,
  SkeletonText,
  Text,
} from "@suns/design-system";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { useEffect, useReducer, useRef, useState } from "react";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
} from "@/components/PlayerHeader/PlayerHeader";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { ReportResponseItem, PlayerMetadataRow } from "@/swagger";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import { apolloApi } from "@/shared/api";
import { toast, ToastType } from "@/shared/utils/toaster";
import { notify } from "@/components/bugsnag";
import {
  ArrowRight,
  CheckCircle,
  LoaderCircle,
} from "@suns/design-system/icons";
import { Link } from "react-router-dom";
import { URL } from "@/shared/utils/route-constant";
import { SunsApiError } from "@suns/api";
import { Page } from "@/components";
import { displayPhase } from "@/shared/utils";
import { CURRENT_PHASE } from "@/shared/const";

export function CreatePhaseReport() {
  const account = useAccount();
  const navigateLogin = useNavigateLogin();
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRow | null>(null);

  const savingPlayersRef = useRef<Record<string, boolean>>({});
  const publishingPlayersRef = useRef<Record<string, boolean>>({});
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const reportsRef = useRef<Record<string, ReportResponseItem>>({});

  const { loading, response, error } = useAsync(phaseReportLoader, {
    authorUsername: account.info?.username ?? "",
  });

  useEffect(() => {
    if (
      response &&
      !selectedPlayer &&
      !loading &&
      Object.keys(response.playerIdReportsMap).length > 0
    ) {
      const firstTeamId = response.sortedTeamPlayersKeys[0];
      const firstPlayerId = response.teamPlayerIdsMap[firstTeamId][0];
      setSelectedPlayer(response.playerIdReportsMap[firstPlayerId][0].player);
    }

    reportsRef.current = response?.phaseReports ?? {};
  }, [response, loading, selectedPlayer]);

  async function handleSubmit(
    report: ReportFormSchema,
    publish: boolean = false
  ) {
    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const existingReport = report.playerId
        ? reportsRef.current[report.playerId]
        : undefined;
      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      if (publish) {
        publishingPlayersRef.current[report.playerId] = true;
      }

      savingPlayersRef.current[report.playerId] = true;
      forceUpdate();

      const res = await apolloApi.saveReport(upsertParams);
      reportsRef.current[res.report.playerId] = res.report;

      if (publish) {
        toast(ToastType.SUCCESS, "Report Published");
      } else {
        toast(ToastType.INFO, "Report Saved");
      }

      return res.report.id;
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        notify(new Error("Auth error trying to save report.", { cause: e }));
        navigateLogin();
      }
      notify(new Error("Error saving report.", { cause: e }));
      toast(
        ToastType.ERROR,
        `Unable to ${publish ? "publish" : "save"} report. Please try again.`
      );
    } finally {
      delete savingPlayersRef.current[report.playerId];
      delete publishingPlayersRef.current[report.playerId];
      forceUpdate();
    }
  }

  if (error) {
    throw new SunsApiError("Error loading the report.", { cause: error });
  }

  if (loading) {
    return <CreatePhaseReportLoading />;
  }

  if (!account.info) {
    throw Error("Error loading account info.");
  }

  const {
    hasReports,
    sortedPlayerIds,
    playerIdReportsMap,
    teamIdTeamMap,
    teamPlayerIdsMap,
    sortedTeamPlayersKeys,
  } = response;

  const activeReport =
    selectedPlayer &&
    reportResponseToFormData(
      selectedPlayer,
      account.info,
      ReportResponseItem.type.PHASE,
      [],
      reportsRef.current[selectedPlayer.id] ?? null,
      playerIdReportsMap[selectedPlayer.id]
    );

  if (!activeReport && hasReports) {
    return <CreatePhaseReportLoading />;
  }

  return (
    <Page
      title="Phase Report"
      render={() => {
        if (!response || !account.info) {
          return null;
        }

        if (!hasReports) {
          return (
            <Card className="text-center">
              <Text heading>
                One or more published Game Reports are required to create a
                Phase Report
              </Text>
              <Link
                to={`/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}`}
              >
                <Button variant="link">
                  Click here to create a Game Report
                </Button>
              </Link>
            </Card>
          );
        }

        if (!selectedPlayer || !activeReport) {
          return null;
        }

        return (
          <Flex direction="down" gap="md">
            <Text size="4xl" heading>
              {displayPhase(CURRENT_PHASE)} Reports
            </Text>
            <Card className="md:hidden">
              <Select
                className="w-full md:hidden"
                value={`${selectedPlayer.id}`}
                onValueChange={(value) => {
                  setSelectedPlayer(playerIdReportsMap[value][0].player);
                }}
              >
                {sortedPlayerIds.map((playerId) => {
                  const isSaving = !!savingPlayersRef.current[playerId];
                  return (
                    <SelectOption
                      key={playerId}
                      value={`${playerId}`}
                      disabled={isSaving}
                    >
                      <PlayerInfo
                        firstReport={playerIdReportsMap[playerId][0]}
                        isActive={`${selectedPlayer.id}` === playerId}
                        isSaving={isSaving}
                        reportCount={playerIdReportsMap[playerId].length}
                        phaseReportStatus={reportsRef.current[playerId]?.status}
                      />
                    </SelectOption>
                  );
                })}
              </Select>
            </Card>
            <Card>
              <Flex>
                <Flex
                  direction="down"
                  gap="lg"
                  className="w-1/3 max-md:hidden lg:w-1/4"
                >
                  {sortedTeamPlayersKeys.map((teamId) => (
                    <Flex key={teamId} direction="down" gap="sm">
                      <Text size="md">{teamIdTeamMap[teamId].fullName}</Text>
                      <Flex
                        direction="down"
                        gap="sm"
                        className="hidden md:flex"
                      >
                        {teamPlayerIdsMap[teamId].map((playerId) => {
                          const isSaving = !!savingPlayersRef.current[playerId];
                          return (
                            <Button
                              variant="ghost"
                              className="justify-start"
                              size="xs"
                              disabled={isSaving}
                              key={playerId}
                              onClick={() => {
                                setSelectedPlayer(
                                  playerIdReportsMap[playerId][0].player
                                );
                              }}
                            >
                              <PlayerInfo
                                firstReport={playerIdReportsMap[playerId][0]}
                                isActive={`${selectedPlayer.id}` === playerId}
                                isSaving={isSaving}
                                reportCount={
                                  playerIdReportsMap[playerId].length
                                }
                                phaseReportStatus={
                                  reportsRef.current[playerId]?.status
                                }
                              />
                            </Button>
                          );
                        })}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>

                <Flex direction="down" gap="lg" align="stretch">
                  <PlayerHeader
                    player={selectedPlayer}
                    teamNbaId={
                      selectedPlayer.currentTeams?.[0]?.nbaId ?? undefined
                    }
                    teamName={selectedPlayer.currentTeams?.[0]?.name}
                    teamImage={
                      selectedPlayer.currentTeams?.[0]?.image ?? undefined
                    }
                    leagueId={
                      selectedPlayer.currentTeams?.[0]?.domesticLeagueId
                    }
                  />
                  <ReportForm
                    key={selectedPlayer.id}
                    report={activeReport}
                    isSaving={!!savingPlayersRef.current[selectedPlayer.id]}
                    isPublishing={
                      !!publishingPlayersRef.current[selectedPlayer.id]
                    }
                    onSubmit={handleSubmit}
                  />
                </Flex>
              </Flex>
            </Card>
          </Flex>
        );
      }}
    />
  );
}

function PlayerInfo({
  firstReport,
  isActive,
  isSaving,
  reportCount,
  phaseReportStatus,
}: {
  firstReport: ReportResponseItem;
  isActive: boolean;
  isSaving: boolean;
  reportCount: number;
  phaseReportStatus?: ReportResponseItem.status;
}) {
  return (
    <Flex direction="right" gap="sm" align="center">
      {firstReport.player.metadata?.[0]?.target &&
        [
          PlayerMetadataRow.target.FREE_AGENT,
          PlayerMetadataRow.target.TRADE,
        ].includes(firstReport.player.metadata[0].target) && (
          <ArrowRight
            size={18}
            className={cn(
              firstReport.player.metadata[0].target ===
                PlayerMetadataRow.target.FREE_AGENT
                ? "text-pink-500"
                : "text-yellow-500",
              !isActive && "opacity-30"
            )}
          />
        )}
      <Text heading={isActive} muted={!isActive}>
        {firstReport.player.lastName}, {firstReport.player.firstName}
      </Text>
      <Badge>{reportCount}</Badge>
      {isSaving ? (
        <LoaderCircle size={18} className="animate-spin" />
      ) : (
        phaseReportStatus && (
          <CheckCircle
            size={18}
            className={cn(
              phaseReportStatus === ReportResponseItem.status.PUBLISHED &&
                "text-green-700"
            )}
          />
        )
      )}
    </Flex>
  );
}

function CreatePhaseReportLoading() {
  return (
    <Card className="grid grid-cols-4 space-x-8">
      <Flex direction="down">
        <SkeletonText rows={5} />
      </Flex>
      <PlayerHeaderSkeleton />
    </Card>
  );
}
